<template>
    <div class="preview_area show_sidebar" v-if="modelValue">
        <!-- <div class="loader_area" v-if="loader || journeyAddTriggerActionLoader"><quote-loader/></div> -->
        <div class="global_setting journey_wpr">
            <button class="close_btn modal-window-close-button" @click="closeModal()"><i class="fas fa-long-arrow-alt-left"></i></button>
            <div class="mobile_alert">
                <h3>Journey is only available on Desktop.</h3>
                <div class="back_btn modal-window-close-button" @click="closeModal()"><i class="fas fa-long-arrow-alt-left"></i>Back</div>
            </div>
            <div class="left_bar">
                <div class="loader_area" v-if="loader || journeyAddTriggerActionLoader"><quote-loader/></div>
                <div v-else>
                    <div class="acton_header mb-4">
                        <div class="left_section">
                            <div class="section_header">
                                <h2>Journey</h2>
                            </div>
                        </div>
                        <div class="right_section w-120">
                            <div class="plan_type mb-2">
                                <label :for="`journey-status-${journey.id}-v2`" class="switch_option capsule_btn p-0 ml-auto">
                                    <input type="checkbox" :id="`journey-status-${journey.id}-v2`" :true-value="1" :false-value="0" v-model="activeJourney.status" @change="handleJourneyStatus(activeJourney)" hidden>
                                    <div><span></span></div>
                                </label>
                                {{ activeJourney.status ? 'Enabled' : 'Disabled' }}
                            </div>
                            <small class="pointer" @click="toggleHistoryPanel()">
                                <i class="fas fa-history"></i> Show History
                            </small>
                        </div>
                    </div>
                    <button type="button" class="preview_btn" @click="selectedTriggerIndex = 1">Settings</button>
                    <div class="trigger_wpr">
                        <ul>
                            <li>
                                <!-- trigger component -->
                                <div class="trigcard_wpr">
                                    <template v-for="(trigger, t) of form.triggers" :key="t">
                                        <div class="trigger_card" :class="{ 'pb-0': triggerScale != t }">
                                            <div class="trigger_info" :id="`trigger-box-${trigger.id}`" :class="{ active: selectedTriggerIndex == trigger.id}" @click="selectTrigger(trigger.id, trigger)">
                                                <button type="button" class="remove_btn" @click.stop="handleDeleteTrigger(trigger.id)" v-if="trigger.id && form.triggers.length > 1">
                                                    <i class="fas fa-trash-alt"></i>
                                                </button>
                                                <div class="icon"><i class="fas fa-bolt"></i></div>
                                                <h5>{{ triggerTitle(trigger.type) }}</h5>
                                                <div class="info">
                                                    <div v-if="trigger.type != 4">
                                                        <template v-if="triggerName(trigger)">
                                                            <ul>
                                                                <li>{{ triggerName(trigger) }}</li>
                                                            </ul>
                                                        </template>
                                                        <h4 v-else>Define Trigger</h4>
                                                    </div>
                                                    <div v-else v-html="triggerUrlHtml(trigger)"></div>
                                                </div>
                                            </div>
                                            <div class="step_type trigger_add" v-if="triggerScale == t">
                                                <button class="add_new" @click.stop="addAction($event)"><i class="fas fa-plus"></i></button>
                                                <div class="add_types">
                                                    <h3>Add a next step to your Journey</h3>
                                                    <ul>
                                                        <li>
                                                            <div class="card_item" @click.stop="addNewAction(0, 'delay', 0, 0, form.actions)">
                                                                <i class="far fa-clock"></i>
                                                                <h4>Add Delay</h4>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div class="card_item" @click.stop="addNewAction(0, 'condition', 0, 0, form.actions)">
                                                                <i class="fas fa-code-branch"></i>
                                                                <h4>Add Condition</h4>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div class="card_item" @click.stop="addNewAction(0, 'action', 0, 0, form.actions)">
                                                                <i class="fas fa-cog"></i>
                                                                <h4>Add Action</h4>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <div class="new_trigger">
                                        <button class="add_new" @click="addTrigger(1)"><i class="fas fa-plus"></i></button>
                                    </div>
                                    <div class="new_trigger left">
                                        <button class="add_new" @click="addTrigger(0)"><i class="fas fa-plus"></i></button>
                                    </div>
                                </div>

                                <draggable v-model="form.actions" :disabled="true" tag="ul" item-key="id" class="journey_list" :animation="200" v-if="form.actions.length">
                                    <template #item="{element, index}">
                                        <span>
                                            <li :class="{ branch: element.action_type == 'condition', 'incomplete-action': element.is_finished == 0 }" :id="`step-${element.id}`">

                                                <!-- delay type -->
                                                <div class="step_info timer" :id="`action-box-${element.id}`" :class="{ 'active' : selectedDelayIndex === element.id }" @click="selectAction(element.id, 'delay', element)" v-if="element.action_type == 'delay'">
                                                    <i class="fas fa-clock"></i>
                                                    <h4 v-html="delayTitle(element.delay)"></h4>
                                                    <button type="button" class="remove_btn" @click.stop="handleDeleteAction(element, 'delay')"><i class="fas fa-trash-alt"></i></button>
                                                </div>

                                                <!-- Condition type -->
                                                <condition-component
                                                    :action="element"
                                                    :delay-title="delayTitle"
                                                    :delete-action="handleDeleteAction"
                                                    :select-action="selectAction"
                                                    :action-Title="actionTitle"
                                                    :action-name="actionName"
                                                    :add-action="addAction"
                                                    :add-new-action="addNewAction"
                                                    :selected-trigger-index="selectedTriggerIndex"
                                                    :selected-action-index="selectedActionIndex"
                                                    :selected-delay-index="selectedDelayIndex"
                                                    :selected-condition-index="selectedConditionIndex"
                                                    :index="index"
                                                    v-if="element.action_type == 'condition'"
                                                    :actions-length="1"
                                                    :all-actions="form.actions"
                                                />

                                                <!-- Action type -->
                                                <div class="step_info mail" :id="`action-box-${element.id}`" :class="{ 'active' : selectedActionIndex === element.id }" @click="selectAction(element.id, 'action', element)" v-if="element.action_type == 'action'">
                                                    <i class="fas fa-cog"></i>
                                                    <h4>{{ actionTitle(element.type) }}</h4>
                                                    <div class="msg_cont">
                                                        <div class="action_details">
                                                            <h4 v-if="!element">Define Action</h4>
                                                            <label class="info">
                                                                <i class="fas fa-info"></i>
                                                                <div class="quick_info">{{ element.title ? element.title : 'No notes' }}</div>
                                                            </label>
                                                            <div v-if="element.type == 10">
                                                                <div class="mail_box">
                                                                    <div class="msg_desc" :class="{'border-0' : element.email.subject}">
                                                                        <p v-html="element.email.subject ? element.email.subject : 'Define Email'" class="mail_subject"></p>
                                                                        <div class="mail_body" v-if="element.email.email" v-html="element.email.email" @click="toggleContent"></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div v-else-if="element.type == 16 || element.type == 17">
                                                                <div v-if="actionName(element)">
                                                                    <p class="mb-2">Pipeline name <span class="cloud-chip">{{ actionName(element).pipeline }}</span></p>
                                                                    <p v-if="actionName(element).stage && element.type == 16">Stage name <span class="cloud-chip">{{ actionName(element).stage }}</span></p>
                                                                    <p v-if="actionName(element).stage && element.type == 17">Current stage to <span class="cloud-chip">{{ actionName(element).stage }}</span></p>
                                                                </div>
                                                                <h4 v-else>Define Action</h4>
                                                            </div>
                                                            <div v-else-if="element.type == 20 || element.type == 22">
                                                                <div v-if="actionName(element)">
                                                                    <p class="mb-2">Task name <span class="cloud-chip">{{ actionName(element).task }}</span></p>
                                                                    <p v-if="actionName(element).users.length" class="task-users">Users <span class="cloud-chip" v-for="(user, u) of actionName(element).users" :key="u">{{ user }}</span></p>
                                                                </div>
                                                                <h4 v-else>Define Action</h4>
                                                            </div>
                                                            <div v-else-if="element.type == 34 || element.type == 35">
                                                                <div>
                                                                    <p><span class="cloud-chip">{{ actionTitle(element.type) }}</span> </p>
                                                                </div>
                                                            </div>
                                                            <div v-else-if="element.type != 4">
                                                                <template v-if="typeof actionName(element) == 'object' && actionName(element).length">
                                                                    <ul>
                                                                        <li v-for="(aname, n) of actionName(element)" :key="n">{{ aname }}</li>
                                                                    </ul>
                                                                </template>
                                                                <div class="mail_body" v-else-if="actionName(element).length" @click="toggleContent">
                                                                    <p v-html="actionName(element)"></p>
                                                                </div>
                                                                <h4 v-else>Define Action</h4>
                                                                <span class="attch_icon" v-if="element.sms.media.length || element.portal_message.media.length">
                                                                    <i class="fas fa-paperclip"></i>
                                                                </span>
                                                            </div>
                                                            <div v-else>
                                                                <ul>
                                                                    <li v-for="(email, e) of element.notification.emails" :key="e">{{ email }}</li>
                                                                </ul>
                                                                <div class="mail_box" v-if="element.notification.sending_method == 1 || element.notification.sending_method == 3">
                                                                    <div class="msg_desc" :class="{'border-0' : element.notification.subject}">
                                                                        <p v-html="element.notification.subject ? element.notification.subject : 'Define Email'"></p>
                                                                    </div>
                                                                </div>
                                                                <div class="sms_box" v-if="element.notification.sending_method == 2 || element.notification.sending_method == 3">
                                                                    <div class="msg_desc" :class="{'border-0' : element.notification.sms_content}">
                                                                        <p v-html="element.notification.sms_content ? element.notification.sms_content : 'Define Message'"></p>
                                                                        <span class="attch_icon" v-if="element.notification.sms_media.length">
                                                                            <i class="fas fa-paperclip"></i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button type="button" class="remove_btn" @click.stop="handleDeleteAction(element, 'action')"><i class="fas fa-trash-alt"></i></button>
                                                </div>

                                                <!-- Add new action Form-->
                                                <div class="step_type" v-if="element.action_type != 'condition'">
                                                    <button class="add_new" @click.stop="addAction($event)">
                                                        <i class="fas fa-plus"></i>
                                                    </button>
                                                    <div class="add_types">
                                                        <h3>Add a next step to your Journey</h3>
                                                        <ul>
                                                            <li>
                                                                <div class="card_item" @click.stop="addNewAction(index + 1, 'delay', 0, 0, form.actions)">
                                                                    <i class="far fa-clock"></i>
                                                                    <h4>Add Delay</h4>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="card_item" @click.stop="addNewAction(index + 1, 'condition', 0, 0, form.actions)">
                                                                    <i class="fas fa-code-branch"></i>
                                                                    <h4>Add Condition</h4>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="card_item" @click.stop="addNewAction(index + 1, 'action', 0, 0, form.actions)">
                                                                    <i class="fas fa-cog"></i>
                                                                    <h4>Add Action</h4>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </li>
                                        </span>
                                    </template>
                                </draggable>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <!-- Edit form -->
            <div class="tabs_content" :class="{ show: selectedTriggerIndex != null || selectedActionIndex != null || selectedDelayIndex != null || selectedConditionIndex != null, blink_me: highlightActionForm }">
                <!-- Trigger form -->
                <Form @submit="handleSubmitTriggerForm" v-slot="{ errors }" class="forms-setting-form" v-if="selectedTriggerIndex != null">
                    <button type="button" class="close_btn" @click="closeTriggerActionPanel()"><i class="fas fa-long-arrow-alt-right"></i></button>
                    <div class="step_details">
                        <div class="journey_details">
                            <div class="acton_header mb-4">
                                <div class="left_section">
                                    <div class="section_header">
                                        <h2>Trigger</h2>
                                    </div>
                                </div>
                            </div>
                            <div class="setting_wpr">
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">Select Trigger</label>
                                        <div class="field_wpr" :class="{ 'has-error': errors.trigger_type }">
                                            <Field autocomplete="off" name="trigger_type" label="trigger type" v-model="selectedTrigger.type" :rules="{ required: activeJourney.status == 1 }">
                                                <multiselect
                                                    v-model="selectedTrigger.type"
                                                    :options="triggers"
                                                    placeholder="Select One.."
                                                    :searchable="true"
                                                    label="label"
                                                    value-prop="value"
                                                >
                                                  <template v-slot:option="{ option }">
                                                      <span :class="{'is-disabled': option.disabled}" @click="option.disabled ? comingSoonAlert('trigger') : ''">
                                                          {{ option.label }}
                                                      </span>
                                                  </template>
                                                </multiselect>
                                            </Field>
                                        </div>
                                        <ErrorMessage name="trigger_type" class="text-danger" />
                                    </div>
                                </div>
                                <div class="edit_section" v-if="selectedTrigger.type == 0 || selectedTrigger.type == 1">
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">Select Sequence</label>
                                            <div class="field_wpr" :class="{ 'has-error': errors.sequence }">
                                                <Field autocomplete="off" name="sequence" v-model="selectedTrigger.sequence_id" :rules="{ required: activeJourney.status == 1 }">
                                                    <multiselect
                                                        v-model="selectedTrigger.sequence_id"
                                                        :options="sequences"
                                                        placeholder="Select One.."
                                                        label="name"
                                                        value-prop="id"
                                                        :searchable="true"
                                                    ></multiselect>
                                                </Field>
                                            </div>
                                            <ErrorMessage name="sequence" class="text-danger" />
                                        </div>
                                    </div>
                                </div>
                                <div class="edit_section" v-if="selectedTrigger.type == 2 || selectedTrigger.type == 3">
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">
                                                Select Tag
                                                <a class="option" @click="createTags = !createTags">Create a Tag</a>
                                            </label>
                                            <div class="field_wpr" :class="{ 'has-error': errors.tag }">
                                                <Field autocomplete="off" name="tag" v-model="selectedTrigger.tag_id" :rules="{ required: activeJourney.status == 1 }">
                                                    <multiselect
                                                        v-model="selectedTrigger.tag_id"
                                                        label="name"
                                                        value-prop="id"
                                                        :searchable="true"
                                                        :group-select="true"
                                                        :options="tagsGroup"
                                                        :groups="true"
                                                        group-label="type"
                                                        group-options="tags"
                                                        :group-hide-empty="true"
                                                        placeholder="Select Tags"
                                                    >
                                                        <template v-slot:grouplabel="{ group }">
                                                            <span :class="group.type.replace(' ', '-').toLowerCase()">{{ group.type }}</span>
                                                        </template>
                                                        <template v-slot:option="{ option }">
                                                            <span>{{ option.name }}</span>
                                                        </template>
                                                        <template v-slot:tag="{ option, handleTagRemove, disabled }">
                                                            <div class="multiselect-tag" :class="option.type.replace(' ', '-').toLowerCase()">
                                                              {{ option.name }}
                                                              <span
                                                                v-if="!disabled"
                                                                class="multiselect-tag-remove"
                                                                @mousedown.prevent="handleTagRemove(option, $event)"
                                                              >
                                                                  <span class="multiselect-tag-remove-icon"></span>
                                                              </span>
                                                            </div>
                                                        </template>
                                                    </multiselect>
                                                </Field>
                                            </div>
                                            <ErrorMessage name="tag" class="text-danger" />
                                        </div>
                                    </div>
                                    <div v-if="createTags" class="form_grp">
                                        <Form v-slot="{ errors }" ref="tag-form">
                                            <div class="group_item">
                                                <label class="input_label">Create a New Tag</label>
                                                <div class="field_wpr has_suffix" :class="{ 'has-error': errors.name }">
                                                    <Field autocomplete="off" type="text" v-model="tagForm.name" name="name" placeholder="Enter Tag Name.." :rules="{ required: activeJourney.status == 1 }" />
                                                    <span class="suffix pointer" @click="handleTagCreate"><i class="fa fa-spinner fa-spin" v-if="tagLoader"></i> {{ tagLoader ? 'Creating' : 'Create' }}</span>
                                                </div>
                                                <ErrorMessage name="name" class="text-danger" />
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                                <div class="edit_section" v-if="selectedTrigger.type == 4">
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">Select Link</label>
                                            <div class="field_wpr" :class="{ 'has-error': errors.link }">
                                                <Field autocomplete="off" name="link" v-model="selectedTriggerLink.id" :rules="{ required: activeJourney.status == 1 }">
                                                    <multiselect
                                                        v-model="selectedTriggerLink.id"
                                                        :groups="true"
                                                        :searchable="true"
                                                        :options="triggerLinks"
                                                        valueProp="id"
                                                        label="title"
                                                        placeholder="Select link"
                                                        :groupSelect="false"
                                                        :groupHideEmpty="true"
                                                        @select="handleTriggerLinkChange"
                                                    >
                                                        <template v-slot:option="{ option }">
                                                            <span v-if="option.$isLabel">
                                                                {{ option.$groupLabel }}
                                                            </span>
                                                            <span v-else>{{ option.title }}</span>
                                                        </template>
                                                    </multiselect>
                                                </Field>
                                            </div>
                                            <ErrorMessage name="link" class="text-danger" />
                                        </div>
                                    </div>
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">Name</label>
                                            <div class="field_wpr" :class="{ 'has-error': errors.name }">
                                                <Field autocomplete="off" name="name" type="text" placeholder="Sample link goes here" v-model="selectedTriggerLink.title" :rules="{ required: activeJourney.status == 1 }" />
                                            </div>
                                            <ErrorMessage name="name" class="text-danger" />
                                        </div>
                                    </div>
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">Destination URL</label>
                                            <div class="field_wpr" :class="{ 'has-error': errors.destination }">
                                                <Field autocomplete="off" name="destination" label="destination url" type="text" placeholder="https://onboardme.io" v-model="selectedTriggerLink.public_url" :rules="{ required: activeJourney.status == 1 }" />
                                            </div>
                                            <ErrorMessage name="destination" class="text-danger" />
                                        </div>
                                    </div>
                                    <div class="info">
                                        <p>You can then select this link from a list of Automation Triggers in any course or broadcast. When a subscriber clicks the link the rule will be triggered and the action will run.</p>
                                    </div>
                                </div>
                                <div class="edit_section" v-if="selectedTrigger.type == 5">
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">Select form</label>
                                            <div class="field_wpr" :class="{ 'has-error': errors.form }">
                                                <Field autocomplete="off" name="form" v-model="selectedTrigger.form_id" :rules="{ required: activeJourney.status == 1 }">
                                                    <multiselect
                                                        v-model="selectedTrigger.form_id"
                                                        :options="forms"
                                                        placeholder="Select One.."
                                                        label="survey_name"
                                                        value-prop="id"
                                                        :searchable="true"
                                                    ></multiselect>
                                                </Field>
                                            </div>
                                            <ErrorMessage name="form" class="text-danger" />
                                        </div>
                                    </div>
                                </div>
                                <div class="edit_section" v-if="selectedTrigger.type == 6 || selectedTrigger.type == 7">
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">Select task</label>
                                            <div class="field_wpr" :class="{ 'has-error': errors.task }">
                                                <Field autocomplete="off" name="task" v-model="selectedTrigger.task_id" :rules="{ required: activeJourney.status == 1 }">
                                                    <multiselect
                                                        v-model="selectedTrigger.task_id"
                                                        :options="tasks"
                                                        placeholder="Select One.."
                                                        label="name"
                                                        value-prop="id"
                                                        :searchable="true"
                                                    ></multiselect>
                                                </Field>
                                            </div>
                                            <ErrorMessage name="task" class="text-danger" />
                                        </div>
                                    </div>
                                </div>
                                <div class="edit_section" v-if="selectedTrigger.type == 8">
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">Select tracking scenario</label>
                                            <div class="field_wpr" :class="{ 'has-error': errors.progress_tracking }">
                                                <Field autocomplete="off" name="progress_tracking" v-model="selectedTrigger.progress_tracking_id" :rules="{ required: activeJourney.status == 1 }" label="progress tracking">
                                                    <multiselect
                                                        v-model="selectedTrigger.progress_tracking_id"
                                                        :options="progressTrackings"
                                                        placeholder="Select One.."
                                                        label="name"
                                                        value-prop="id"
                                                        :searchable="true"
                                                    ></multiselect>
                                                </Field>
                                            </div>
                                            <ErrorMessage name="progress_tracking" class="text-danger" />
                                        </div>
                                    </div>
                                </div>
                                <div class="edit_section" v-if="selectedTrigger.type == 9">
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">Select habit scenario</label>
                                            <div class="field_wpr" :class="{ 'has-error': errors.habit_tracking }">
                                                <Field autocomplete="off" name="habit_tracking" v-model="selectedTrigger.habit_tracking_id" :rules="{ required: activeJourney.status == 1 }" label="habit tracking">
                                                    <multiselect
                                                        v-model="selectedTrigger.habit_tracking_id"
                                                        :options="habitTrackings"
                                                        placeholder="Select One.."
                                                        label="name"
                                                        value-prop="id"
                                                        :searchable="true"
                                                    ></multiselect>
                                                </Field>
                                            </div>
                                            <ErrorMessage name="habit_tracking" class="text-danger" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="action_wpr">
                        <button type="button" :disabled="triggerSaveLoader" class="btn cancel_btn" @click="closeTriggerActionPanel()">Cancel</button>
                        <button :disabled="triggerSaveLoader" class="btn save_btn" id="save-trigger-button">
                            <i class="fa fa-spin fa-spinner" v-if="triggerSaveLoader"></i> {{ triggerSaveLoader ? 'Saving' : 'Save' }}
                        </button>
                    </div>
                </Form>

                <!-- Action, delay & Condition form -->
                <Form @submit="handleSubmitActionForm" v-slot="{ errors }" class="forms-setting-form" v-if="selectedActionIndex != null || selectedDelayIndex != null || selectedConditionIndex != null">
                    <button type="button" class="close_btn" @click="closeTriggerActionPanel()"><i class="fas fa-long-arrow-alt-right"></i></button>
                    <div class="step_details">
                        <div class="journey_details">
                            <div class="acton_header mb-4">
                                <div class="left_section">
                                    <div class="section_header">
                                        <h2 v-if="selectedActionIndex != null">Action</h2>
                                        <h2 v-if="selectedDelayIndex != null">Delay</h2>
                                        <h2 v-if="selectedConditionIndex != null">Condition</h2>
                                    </div>
                                </div>
                                <div class="right_section">
                                    <label for="preview_message" class="switch_option capsule_btn p-0 border-0" v-if="selectedActionIndex != null && (selectedAction.type == 4 || selectedAction.type == 7 || selectedAction.type == 10)">
                                        <h5 class="mr-3">Full View</h5>
                                        <input type="checkbox" id="preview_message" v-model="fullPreviewMessage" hidden />
                                        <div><span></span></div>
                                    </label>
                                    <div class="text-right color-d4" :class="{ 'mt-1': selectedActionIndex != null && (selectedAction.type == 4 || selectedAction.type == 7 || selectedAction.type == 10) }" v-if="selectedAction.ref >= 0">
                                        #{{ selectedAction.ref }}
                                    </div>
                                </div>
                            </div>

                            <!--Actions Form-->
                            <div class="setting_wpr" v-if="selectedActionIndex != null">
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">Select Action</label>
                                        <div class="field_wpr" :class="{ 'has-error': errors.action_type }">
                                            <Field autocomplete="off" name="action_type" v-model="selectedAction.type" :rules="{ required: activeJourney.status == 1 }" label="action type">
                                                <multiselect
                                                    v-model="selectedAction.type"
                                                    :options="actions"
                                                    placeholder="Select One.."
                                                    :searchable="true"
                                                >
                                                    <template v-slot:option="{ option }">
                                                        <span @click="option.disabled ? comingSoonAlert('action') : ''">
                                                            {{ option.label }}
                                                        </span>
                                                    </template>
                                                </multiselect>
                                            </Field>
                                        </div>
                                        <ErrorMessage name="action_type" class="text-danger" />
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <div class="edit_section" v-if="selectedAction.type == 0">
                                        <div class="form_grp mb-3">
                                            <div class="group_item">
                                                <label class="input_label">Select Sequence</label>
                                                <div class="field_wpr" :class="{ 'has-error': errors.sequence }">
                                                    <Field autocomplete="off" name="sequence" v-model="selectedAction.sequence.id" :rules="{ required: activeJourney.status == 1 }">
                                                        <multiselect
                                                            v-model="selectedAction.sequence.id"
                                                            :options="sequences"
                                                            placeholder="Select One.."
                                                            label="name"
                                                            value-prop="id"
                                                            :searchable="true"
                                                        ></multiselect>
                                                    </Field>
                                                </div>
                                                <ErrorMessage name="sequence" class="text-danger" />
                                            </div>
                                        </div>
                                        <div v-if="selectedAction.sequence.id && selectedAction.type == 0">
                                            <label for="start_msg" class="switch_option capsule_btn border-0">
                                                <h5 class="large">Start At Message 1</h5>
                                                <input type="checkbox" id="start_msg" :true-value="1" :false-value="0" v-model="selectedAction.sequence.msg_start_one" hidden>
                                                <div><span></span></div>
                                            </label>
                                            <div class="form_grp" v-if="selectedAction.sequence.msg_start_one == 0">
                                                <div class="group_item">
                                                    <div class="field_wpr">
                                                        <multiselect
                                                            v-model="selectedAction.sequence.email_order"
                                                            :options="sequenceEmails"
                                                            placeholder="Select One.."
                                                            label="name"
                                                            value-prop="order"
                                                        ></multiselect>
                                                    </div>
                                                </div>
                                            </div>
                                            <label for="throttle_msg" class="switch_option capsule_btn border-0">
                                                <h5 class="large">Throttle for contact timezone</h5>
                                                <input type="checkbox" id="throttle_msg" :true-value="1" :false-value="0" v-model="selectedAction.sequence.throttle_tz" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="edit_section" v-if="selectedAction.type == 1">
                                        <div class="form_grp mb-3">
                                            <div class="group_item">
                                                <label class="input_label">Select Sequence</label>
                                                <div class="field_wpr" :class="{ 'has-error': errors.sequence }">
                                                    <Field autocomplete="off" name="sequence" v-model="selectedAction.sequence.id" :rules="{ required: activeJourney.status == 1 }">
                                                        <multiselect
                                                            v-model="selectedAction.sequence.id"
                                                            :options="sequences"
                                                            placeholder="Select One.."
                                                            label="name"
                                                            value-prop="id"
                                                            :searchable="true"
                                                            mode="tags"
                                                        ></multiselect>
                                                    </Field>
                                                </div>
                                                <ErrorMessage name="sequence" class="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="edit_section" v-if="selectedAction.type == 2 || selectedAction.type == 3">
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">
                                                    Select tag
                                                    <a class="option" @click="createTags = !createTags">Create a Tag</a>
                                                </label>
                                                <div class="field_wpr" :class="{ 'has-error': errors.tag }">
                                                    <Field autocomplete="off" name="tag" v-model="selectedAction.tag_id" :rules="{ required: activeJourney.status == 1 }">
                                                        <multiselect
                                                            v-model="selectedAction.tag_id"
                                                            label="name"
                                                            value-prop="id"
                                                            :searchable="true"
                                                            :group-select="true"
                                                            :options="tagsGroup"
                                                            :groups="true"
                                                            group-label="type"
                                                            group-options="tags"
                                                            :group-hide-empty="true"
                                                            placeholder="Select Tags"
                                                            mode="tags"
                                                        >
                                                            <template v-slot:grouplabel="{ group }">
                                                                <span :class="group.type.replace(' ', '-').toLowerCase()">{{ group.type }}</span>
                                                            </template>
                                                            <template v-slot:option="{ option }">
                                                                <span>{{ option.name }}</span>
                                                            </template>
                                                            <template v-slot:tag="{ option, handleTagRemove, disabled }">
                                                                <div class="multiselect-tag" :class="option.type.replace(' ', '-').toLowerCase()">
                                                                  {{ option.name }}
                                                                  <span
                                                                    v-if="!disabled"
                                                                    class="multiselect-tag-remove"
                                                                    @mousedown.prevent="handleTagRemove(option, $event)"
                                                                  >
                                                                      <span class="multiselect-tag-remove-icon"></span>
                                                                  </span>
                                                                </div>
                                                            </template>
                                                        </multiselect>
                                                    </Field>
                                                </div>
                                                <ErrorMessage name="tag" class="text-danger" />
                                            </div>
                                        </div>
                                        <div v-if="createTags" class="form_grp">
                                            <Form v-slot="{ errors }" ref="tag-form">
                                                <div class="group_item">
                                                    <label class="input_label">Create a New Tag</label>
                                                    <div class="field_wpr has_suffix" :class="{ 'has-error': errors.name }">
                                                        <Field autocomplete="off" type="text" v-model="tagForm.name" name="name" placeholder="Enter Tag Name.." :rules="{ required: activeJourney.status == 1 }" />
                                                        <span class="suffix pointer" @click="handleTagCreate"><i class="fa fa-spinner fa-spin" v-if="tagLoader"></i> {{ tagLoader ? 'Creating' : 'Create' }}</span>
                                                    </div>
                                                    <ErrorMessage name="name" class="text-danger" />
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                    <div class="mt-5" v-if="selectedAction.type == 4">
                                        <sending-method v-model="selectedAction.notification.sending_method" />
                                        <div class="mt-5" v-if="selectedAction.notification.sending_method == 1 || selectedAction.notification.sending_method == 3">
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Email Recipient <small>Press enter to add</small></label>
                                                    <div class="field_wpr m-0" :class="{ 'has-error': errors.email_recipient }">
                                                        <Field autocomplete="off" name="email_recipient" v-model="selectedAction.notification.emails" :rules="{ required: activeJourney.status == 1 }" label="email recipient">
                                                            <vue-tags-input :tags="selectedAction.notification.emails" @on-tags-changed="handleEmailRecipientUpdate" :add-tag-on-blur="true" />
                                                        </Field>
                                                    </div>
                                                    <ErrorMessage name="email_recipient" class="text-danger" />
                                                </div>
                                            </div>
                                            <email-component v-if="!resetEmailComponent" v-model="notificationEmail" :errors="errors" preview-module="email" ref="notification-email-component" :handle-default-email="handleDefault3rdPartyEmailNotification" is-full-view />
                                        </div>
                                        <div class="mt-5" v-if="selectedAction.notification.sending_method == 2 || selectedAction.notification.sending_method == 3">
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">SMS Recipient</label>
                                                    <div class="field_wpr m-0" :class="{ 'has-error': errors.sms_recipient }">
                                                        <Field autocomplete="off" name="sms_recipient" v-model="selectedAction.notification.numbers" :rules="{ required: activeJourney.status == 1 }" label="sms recipient">
                                                            <vue-tags-input :tags="selectedAction.notification.numbers" @on-tags-changed="handleSmsRecipientUpdate" :add-tag-on-blur="true" />
                                                        </Field>
                                                    </div>
                                                    <ErrorMessage name="sms_recipient" class="text-danger" />
                                                </div>
                                            </div>
                                            <sms-component v-model="selectedAction.notification.sms_content" :media="selectedAction.notification.sms_media" media-field-name="notification_sms_media" :default-sms-handler="true" :handle-default-sms="handleDefault3rdPartySMSNotification" />
                                        </div>
                                    </div>
                                    <div class="edit_section" v-if="selectedAction.type == 5 || selectedAction.type == 14">
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Select playbook</label>
                                                <div class="field_wpr" :class="{ 'has-error': errors.playbook }">
                                                    <Field autocomplete="off" name="playbook" v-model="selectedAction.playbook_id" :rules="{ required: activeJourney.status == 1 }">
                                                        <multiselect
                                                            v-model="selectedAction.playbook_id"
                                                            :options="playbooks"
                                                            placeholder="Select One.."
                                                            label="title"
                                                            value-prop="id"
                                                            :searchable="true"
                                                            mode="tags"
                                                        ></multiselect>
                                                    </Field>
                                                </div>
                                                <ErrorMessage name="playbook" class="text-danger" />
                                            </div>
                                        </div>
                                        <sending-method v-model="selectedAction.sending_method" v-if="selectedAction.type == 5" />
                                    </div>
                                    <div class="edit_section" v-if="selectedAction.type == 6">
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Select page</label>
                                                <div class="field_wpr" :class="{ 'has-error': errors.page }">
                                                    <Field autocomplete="off" name="page" v-model="selectedAction.page_id" :rules="{ required: activeJourney.status == 1 }">
                                                        <multiselect
                                                            v-model="selectedAction.page_id"
                                                            :options="pages.filter((page) => page.is_private == selectedAction.is_private)"
                                                            placeholder="Select One.."
                                                            label="title"
                                                            value-prop="id"
                                                            :searchable="true"
                                                            mode="tags"
                                                        ></multiselect>
                                                    </Field>
                                                </div>
                                                <ErrorMessage name="page" class="text-danger" />
                                            </div>
                                        </div>
                                        <label for="private-page" class="switch_option capsule_btn border-0 mt-2">
                                            <h5 class="large">Private Page</h5>
                                            <input type="checkbox" id="private-page" :true-value="1" :false-value="0" v-model="selectedAction.is_private" hidden>
                                            <div><span></span></div>
                                        </label>
                                        <label for="page-client-portal" class="switch_option capsule_btn border-0 mt-2" v-if="selectedAction.is_private == 0">
                                            <h5 class="large">Show in client portal</h5>
                                            <input type="checkbox" id="page-client-portal" :true-value="1" :false-value="0" v-model="selectedAction.show_in_portal" hidden>
                                            <div><span></span></div>
                                        </label>
                                        <sending-method v-model="selectedAction.sending_method" />
                                    </div>
                                    <div class="edit_section" v-if="selectedAction.type == 27">
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Select page</label>
                                                <div class="field_wpr" :class="{ 'has-error': errors.page }">
                                                    <Field autocomplete="off" name="page" v-model="selectedAction.page_id" :rules="{ required: activeJourney.status == 1 }">
                                                        <multiselect
                                                            v-model="selectedAction.page_id"
                                                            :options="pages"
                                                            placeholder="Select One.."
                                                            label="title"
                                                            value-prop="id"
                                                            :searchable="true"
                                                            mode="tags"
                                                        ></multiselect>
                                                    </Field>
                                                </div>
                                                <ErrorMessage name="page" class="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="selectedAction.type == 7">
                                        <sms-component v-model="selectedAction.sms.content" :media="selectedAction.sms.media" media-field-name="sms_sms_media" />
                                        <label for="throttle_sms" class="switch_option capsule_btn border-0 mt-2">
                                            <h5 class="large">Throttle for contact timezone</h5>
                                            <input type="checkbox" id="throttle_sms" :true-value="1" :false-value="0" v-model="selectedAction.sms.throttle_tz" hidden>
                                            <div><span></span></div>
                                        </label>
                                    </div>
                                    <div class="edit_section" v-if="selectedAction.type == 8">
                                        <ul class="template_list">
                                            <li>
                                                <div class="card_item" :class="{ active: templateType == 'email' }" @click="selectTemplate($event, 'email')">
                                                    <img src="@/assets/images/mail.svg">Email
                                                </div>
                                            </li>
                                            <li>
                                                <div class="card_item" :class="{ active: templateType == 'sms' }" @click="selectTemplate($event, 'sms')">
                                                    <img src="@/assets/images/sms.svg">SMS
                                                </div>
                                            </li>
                                            <li>
                                                <div class="card_item" :class="{ active: templateType == 'email,sms' }" @click="selectTemplate($event, 'email,sms')">
                                                    <img src="@/assets/images/template.svg">Multi-Channel
                                                </div>
                                            </li>
                                        </ul>
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Select Category</label>
                                                <div class="field_wpr" :class="{ 'has-error': errors.template_category }">
                                                    <Field autocomplete="off" name="template_category" label="template category" v-model="selectedAction.template_category" :rules="{ required: activeJourney.status == 1 }">
                                                        <multiselect
                                                            v-model="selectedAction.template_category"
                                                            :options="templateCategories"
                                                            value-prop="type"
                                                            label="type"
                                                            :searchable="true"
                                                            placeholder="Select category"
                                                        ></multiselect>
                                                    </Field>
                                                </div>
                                                <ErrorMessage name="template_category" class="text-danger" />
                                            </div>
                                        </div>
                                        <div class="form_grp mb-3" v-if="selectedAction.template_category">
                                            <div class="group_item">
                                                <label class="input_label">Select template</label>
                                                <div class="field_wpr" :class="{ 'has-error': errors.template }">
                                                    <Field autocomplete="off" name="template" v-model="selectedAction.template_id" :rules="{ required: activeJourney.status == 1 }">
                                                        <multiselect
                                                            v-model="selectedAction.template_id"
                                                            :options="templates"
                                                            placeholder="Select One.."
                                                            label="title"
                                                            value-prop="id"
                                                            :searchable="true"
                                                            mode="tags"
                                                            :loader="templateAjaxLoader"
                                                        ></multiselect>
                                                    </Field>
                                                </div>
                                                <ErrorMessage name="template" class="text-danger" />
                                            </div>
                                        </div>
                                        <sending-method v-model="selectedAction.sending_method" />
                                    </div>
                                    <div class="edit_section" v-if="selectedAction.type == 9">
                                        <div class="form_grp mb-3">
                                            <div class="group_item">
                                                <label class="input_label">Select form</label>
                                                <div class="field_wpr" :class="{ 'has-error': errors.form }">
                                                    <Field autocomplete="off" name="form" v-model="selectedAction.form_id" :rules="{ required: activeJourney.status == 1 }">
                                                        <multiselect
                                                            v-model="selectedAction.form_id"
                                                            :options="forms.filter((form) => form.is_private == selectedAction.is_private)"
                                                            placeholder="Select One.."
                                                            label="survey_name"
                                                            value-prop="id"
                                                            :searchable="true"
                                                            mode="tags"
                                                        ></multiselect>
                                                    </Field>
                                                </div>
                                                <ErrorMessage name="form" class="text-danger" />
                                            </div>
                                        </div>
                                        <label for="private-page" class="switch_option capsule_btn border-0 mt-2">
                                            <h5 class="large">Private Form</h5>
                                            <input type="checkbox" id="private-page" :true-value="1" :false-value="0" v-model="selectedAction.is_private" hidden>
                                            <div><span></span></div>
                                        </label>
                                        <label for="page-client-portal" class="switch_option capsule_btn border-0 mt-2" v-if="selectedAction.is_private == 0">
                                            <h5 class="large">Show in client portal</h5>
                                            <input type="checkbox" id="page-client-portal" :true-value="1" :false-value="0" v-model="selectedAction.show_in_portal" hidden>
                                            <div><span></span></div>
                                        </label>
                                        <sending-method v-model="selectedAction.sending_method" />
                                    </div>
                                    <div class="edit_section" v-if="selectedAction.type == 28">
                                        <div class="form_grp mb-3">
                                            <div class="group_item">
                                                <label class="input_label">Select form</label>
                                                <div class="field_wpr" :class="{ 'has-error': errors.form }">
                                                    <Field autocomplete="off" name="form" v-model="selectedAction.form_id" :rules="{ required: activeJourney.status == 1 }">
                                                        <multiselect
                                                            v-model="selectedAction.form_id"
                                                            :options="forms"
                                                            placeholder="Select One.."
                                                            label="survey_name"
                                                            value-prop="id"
                                                            :searchable="true"
                                                            mode="tags"
                                                        ></multiselect>
                                                    </Field>
                                                </div>
                                                <ErrorMessage name="form" class="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="selectedAction.type == 10">
                                        <email-component v-if="!resetEmailComponent" v-model="singleEmail" :errors="errors" :has-reset-default="false" preview-module="email" ref="email-component" is-full-view />
                                    </div>
                                    <div v-if="selectedAction.type == 11">
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Points Awarded</label>
                                                <div class="field_wpr" :class="{ 'has-error': errors.points }">
                                                    <Field autocomplete="off" name="points" type="number" min="0" v-model.number="selectedAction.rewards.points" @keyup="selectedAction.rewards.points = Number(selectedAction.rewards.points).toString();" :rules="{ required: activeJourney.status == 1 }" />
                                                </div>
                                                <ErrorMessage name="points" class="text-danger" />
                                            </div>
                                        </div>
                                        <label for="throttle_sms" class="switch_option capsule_btn border-0 mt-2">
                                            <h5 class="large">Subscriber notifications</h5>
                                            <input type="checkbox" id="throttle_sms" :true-value="1" :false-value="0" v-model="selectedAction.rewards.notification" hidden>
                                            <div><span></span></div>
                                        </label>
                                        <div class="edit_section mt-3" v-if="selectedAction.rewards">
                                            <sending-method v-model="selectedAction.rewards.sending_method" />
                                        </div>
                                        <div class="form_grp mt-3">
                                            <div class="group_item">
                                                <label class="input_label">You Just Earn {{ selectedAction.rewards.points }} Points For {{ selectedAction.rewards.reward_reason }}</label>
                                                <div class="field_wpr" :class="{ 'has-error': errors.reward_reason }">
                                                    <Field autocomplete="off" name="reward_reason" type="text" placeholder="Type Here..." v-model="selectedAction.rewards.reward_reason" :rules="{ required: activeJourney.status == 1 }" label="reward reason" />
                                                </div>
                                                <ErrorMessage name="reward_reason" class="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="edit_section" v-if="selectedAction.type == 12">
                                        <div class="form_grp mb-3">
                                            <div class="group_item">
                                                <label class="input_label">Select journey</label>
                                                <div class="field_wpr" :class="{ 'has-error': errors.journey }">
                                                    <Field autocomplete="off" name="journey" v-model="selectedAction.journey_id" :rules="{ required: activeJourney.status == 1 }">
                                                        <multiselect
                                                            v-model="selectedAction.journey_id"
                                                            :options="journeys"
                                                            placeholder="Select One.."
                                                            label="name"
                                                            value-prop="id"
                                                            :searchable="true"
                                                            mode="tags"
                                                        ></multiselect>
                                                    </Field>
                                                </div>
                                                <ErrorMessage name="journey" class="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="edit_section" v-if="selectedAction.type == 13">
                                        <div class="form_grp mb-3">
                                            <div class="group_item">
                                                <label class="input_label">Select segment</label>
                                                <div class="field_wpr" :class="{ 'has-error': errors.tab }">
                                                    <Field autocomplete="off" name="tab" v-model="selectedAction.tab" :rules="{ required: activeJourney.status == 1 }">
                                                        <multiselect
                                                            v-model="selectedAction.tab"
                                                            :groups="true"
                                                            :searchable="true"
                                                            :options="statusesGroup"
                                                            valueProp="value"
                                                            label="title"
                                                            placeholder="Select segment"
                                                            :group-select="false"
                                                            group-label="type"
                                                            group-options="items"
                                                            :group-hide-empty="true"
                                                        >
                                                            <template v-slot:grouplabel="{ group }">
                                                                <span class="status-tabs-header">{{ group.type }}</span>
                                                            </template>
                                                            <template v-slot:option="{ option }">
                                                                <span>{{ option.title }}</span>
                                                            </template>
                                                        </multiselect>
                                                    </Field>
                                                </div>
                                                <ErrorMessage name="tab" class="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="edit_section" v-if="selectedAction.type == 16 || selectedAction.type == 17">
                                        <div class="form_grp mb-3">
                                            <div class="group_item">
                                                <label class="input_label">Select pipeline</label>
                                                <div class="field_wpr" :class="{ 'has-error': errors.pipeline }">
                                                    <Field autocomplete="off" name="pipeline" v-model="selectedAction.pipeline.id" :rules="{ required: activeJourney.status == 1 }">
                                                        <multiselect
                                                            v-model="selectedAction.pipeline.id"
                                                            :options="pipelines"
                                                            placeholder="Select One.."
                                                            label="title"
                                                            value-prop="id"
                                                            :searchable="true"
                                                        ></multiselect>
                                                    </Field>
                                                </div>
                                                <ErrorMessage name="pipeline" class="text-danger" />
                                            </div>
                                        </div>
                                        <div class="form_grp mb-3" v-if="selectStage && selectedAction.pipeline.id">
                                            <div class="group_item">
                                                <label class="input_label">Which stage should contact be added to</label>
                                                <div class="field_wpr" :class="{ 'has-error': errors.stage }">
                                                    <Field autocomplete="off" name="stage" v-model="selectedAction.pipeline.stage_id" :rules="{ required: activeJourney.status == 1 }">
                                                        <multiselect
                                                            v-model="selectedAction.pipeline.stage_id"
                                                            :options="pipelineStages"
                                                            placeholder="Select One.."
                                                            label="name"
                                                            value-prop="id"
                                                            :searchable="true"
                                                        ></multiselect>
                                                    </Field>
                                                </div>
                                                <ErrorMessage name="stage" class="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="edit_section" v-if="selectedAction.type == 18">
                                        <div class="form_grp mb-3">
                                            <div class="group_item">
                                                <label class="input_label">Select pipeline</label>
                                                <div class="field_wpr" :class="{ 'has-error': errors.pipeline }">
                                                    <Field autocomplete="off" name="pipeline" v-model="selectedAction.pipeline.id" :rules="{ required: activeJourney.status == 1 }">
                                                        <multiselect
                                                            v-model="selectedAction.pipeline.id"
                                                            :options="pipelines"
                                                            placeholder="Select One.."
                                                            label="title"
                                                            value-prop="id"
                                                            :searchable="true"
                                                            mode="tags"
                                                        ></multiselect>
                                                    </Field>
                                                </div>
                                                <ErrorMessage name="pipeline" class="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="edit_section" v-if="selectedAction.type == 19 || selectedAction.type == 21">
                                        <div class="form_grp mb-3">
                                            <div class="group_item">
                                                <label class="input_label">Select task</label>
                                                <div class="field_wpr" :class="{ 'has-error': errors.task }">
                                                    <Field autocomplete="off" name="task" v-model="selectedAction.task.id" :rules="{ required: activeJourney.status == 1 }">
                                                        <multiselect
                                                            v-model="selectedAction.task.id"
                                                            :options="tasks"
                                                            placeholder="Select One.."
                                                            label="name"
                                                            value-prop="id"
                                                            :searchable="true"
                                                        ></multiselect>
                                                    </Field>
                                                </div>
                                                <ErrorMessage name="task" class="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="edit_section" v-if="selectedAction.type == 20 || selectedAction.type == 22">
                                        <div class="form_grp mb-3">
                                            <div class="group_item">
                                                <label class="input_label">Select task</label>
                                                <div class="field_wpr" :class="{ 'has-error': errors.task }">
                                                    <Field autocomplete="off" name="task" v-model="selectedAction.task.id" :rules="{ required: activeJourney.status == 1 }">
                                                        <multiselect
                                                            v-model="selectedAction.task.id"
                                                            :options="userTasks"
                                                            placeholder="Select One.."
                                                            label="name"
                                                            value-prop="id"
                                                            :searchable="true"
                                                        ></multiselect>
                                                    </Field>
                                                </div>
                                                <ErrorMessage name="task" class="text-danger" />
                                            </div>
                                        </div>
                                        <div class="form_grp mb-3">
                                            <div class="group_item">
                                                <label class="input_label">Select user</label>
                                                <div class="field_wpr" :class="{ 'has-error': errors.user }">
                                                    <Field autocomplete="off" name="user" v-model="selectedAction.task.users" :rules="{ required: activeJourney.status == 1 }">
                                                        <multiselect
                                                            v-model="selectedAction.task.users"
                                                            :options="organizationUsers"
                                                            value-prop="id"
                                                            label="full_name"
                                                            :searchable="true"
                                                            placeholder="Select One.."
                                                            autocomplete="nofill"
                                                            mode="tags"
                                                        >
                                                            <template v-slot:singlelabel="{ value }">
                                                                <div class="multiselect-single-label">
                                                                  <img class="character-label-icon" :src="value.profile_pic"> {{ value.full_name }}
                                                                </div>
                                                            </template>

                                                            <template v-slot:option="{ option }">
                                                                <div class="multiselect-avatar">
                                                                    <img class="character-option-icon" :src="option.profile_pic">
                                                                </div>
                                                                <div class="multiselect-title-content">
                                                                    <div class="multiselect-title">{{ option.full_name }}</div>
                                                                    <div class="multiselect-sub-title">{{ option.email }}</div>
                                                                </div>
                                                            </template>
                                                        </multiselect>
                                                    </Field>
                                                </div>
                                                <ErrorMessage name="user" class="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="edit_section" v-if="selectedAction.type == 23 || selectedAction.type == 24">
                                        <div class="form_grp mb-3">
                                            <div class="group_item">
                                                <label class="input_label">Select task</label>
                                                <div class="field_wpr" :class="{ 'has-error': errors.task }">
                                                    <Field autocomplete="off" name="task" v-model="selectedAction.task.id" :rules="{ required: activeJourney.status == 1 }">
                                                        <multiselect
                                                            v-model="selectedAction.task.id"
                                                            :options="tasks"
                                                            placeholder="Select One.."
                                                            label="name"
                                                            value-prop="id"
                                                            :searchable="true"
                                                            mode="tags"
                                                        ></multiselect>
                                                    </Field>
                                                </div>
                                                <ErrorMessage name="task" class="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="edit_section" v-if="selectedAction.type == 25 || selectedAction.type == 26">
                                        <div class="form_grp mb-3">
                                            <div class="group_item">
                                                <label class="input_label">Select tracking scenario </label>
                                                <div class="field_wpr" :class="{ 'has-error': errors.progress_tracking_id }">
                                                    <Field autocomplete="off" name="progress_tracking_id" v-model="selectedAction.progress_tracking_id" :rules="{ required: activeJourney.status == 1 }">
                                                        <multiselect
                                                            v-model="selectedAction.progress_tracking_id"
                                                            :options="progressTrackings"
                                                            placeholder="Select One.."
                                                            label="name"
                                                            value-prop="id"
                                                            :searchable="true"
                                                            mode="tags"
                                                        ></multiselect>
                                                    </Field>
                                                </div>
                                                <ErrorMessage name="progress_tracking_id" class="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="edit_section" v-if="selectedAction.type == 29 || selectedAction.type == 30">
                                        <div class="form_grp mb-3">
                                            <div class="group_item">
                                                <label class="input_label">Select habit scenario</label>
                                                <div class="field_wpr" :class="{ 'has-error': errors.habit_tracking_id }">
                                                    <Field autocomplete="off" name="habit_tracking_id" v-model="selectedAction.habit_tracking_id" :rules="{ required: activeJourney.status == 1 }">
                                                        <multiselect
                                                            v-model="selectedAction.habit_tracking_id"
                                                            :options="habitTrackings"
                                                            placeholder="Select One.."
                                                            label="name"
                                                            value-prop="id"
                                                            :searchable="true"
                                                            mode="tags"
                                                        ></multiselect>
                                                    </Field>
                                                </div>
                                                <ErrorMessage name="habit_tracking_id" class="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="selectedAction.type == 31">
                                        <portal-message v-model="selectedAction.portal_message.content" :portal-media="selectedAction.portal_message.media" media-field-name="portal_message_media" />
                                    </div>
                                    <div class="edit_section" v-if="selectedAction.type == 32 || selectedAction.type == 33">
                                        <div class="form_grp mb-3">
                                            <div class="group_item">
                                                <label class="input_label">Select workout</label>
                                                <div class="field_wpr" :class="{ 'has-error': errors.workout_id }">
                                                    <Field autocomplete="off" name="workout_id" v-model="selectedAction.workout_id" :rules="{ required: activeJourney.status == 1 }">
                                                        <multiselect
                                                            v-model="selectedAction.workout_id"
                                                            :options="workouts"
                                                            placeholder="Select One.."
                                                            label="name"
                                                            value-prop="id"
                                                            :searchable="true"
                                                            mode="tags"
                                                        ></multiselect>
                                                    </Field>
                                                </div>
                                                <ErrorMessage name="workout_id" class="text-danger" />
                                            </div>
                                        </div>
                                        <template v-if="selectedAction.type == 32">
                                            <div class="extra_abc">
                                                <label for="add-in-classroom" class="switch_option capsule_btn">
                                                    <h5>Add in classroom</h5>
                                                    <input type="checkbox" name="add_workout_in_classroom" :true-value="1" :false-value="0" id="add-in-classroom" v-model="selectedAction.workout_settings.in_classroom" hidden>
                                                    <div><span></span></div>
                                                </label>
                                            </div>
                                            <div class="extra_abc">
                                                <label for="add-in-calendar" class="switch_option capsule_btn">
                                                    <h5>Add in calendar</h5>
                                                    <input type="checkbox" name="add_workout_in_calendar" :true-value="1" :false-value="0" id="add-in-calendar" v-model="selectedAction.workout_settings.in_calendar" hidden>
                                                    <div><span></span></div>
                                                </label>
                                            </div>
                                            <div class="start_plan" v-if="selectedAction.workout_settings.in_calendar">
                                                <div class="mt-2">
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">Date</label>
                                                            <div class="field_wpr">
                                                                <datepicker v-model="selectedAction.workout_settings.calendar_date" :timezone="user.timezone ? user.timezone : 'UTC'" :min-date="moment().format('YYYY-MM-DD')" autoApply placeholder="MM/DD/YYYY" format="MM/dd/yyyy"></datepicker>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">Time</label>
                                                            <div class="group-col-3">
                                                                <div class="field_wpr">
                                                                    <multiselect v-model="selectedAction.workout_settings.calendar_hour" :options="hours"></multiselect>
                                                                </div>
                                                                <div class="field_wpr">
                                                                    <multiselect v-model="selectedAction.workout_settings.calendar_minute" :options="minutes"></multiselect>
                                                                </div>
                                                                <div class="field_wpr">
                                                                    <multiselect v-model="selectedAction.workout_settings.calendar_ampm" :options="clocks"></multiselect>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">Timezone</label>
                                                            <div class="field_wpr">
                                                                <multiselect
                                                                v-model="selectedAction.workout_settings.timezone"
                                                                :options="timezones"
                                                                valueProp="timezone"
                                                                label="timezone"
                                                                :searchable="true"
                                                                >
                                                                    <template v-slot:option="{ option }">
                                                                        <span>{{ `${ option.abbr} ${ option.utc_offset_str} ${ option.timezone}` }}</span>
                                                                    </template>
                                                                </multiselect>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                    <div class="edit_section p-0" v-if="selectedAction.type == 36">
                                        <div class="form_grp mb-3">
                                            <div class="group_item">
                                                <label class="input_label">Select Workout Program</label>
                                                <div class="field_wpr" :class="{ 'has-error': errors.workout }">
                                                    <Field autocomplete="off" name="workout" v-model="selectedAction.workout_plan.ids" rules="required" label="workout">
                                                        <multiselect
                                                            v-model="selectedAction.workout_plan.ids"
                                                            label="name"
                                                            value-prop="id"
                                                            :searchable="true"
                                                            :options="workoutPlans"
                                                            placeholder="Select Workout Program"
                                                            mode="tags"
                                                        ></multiselect>
                                                    </Field>
                                                </div>
                                                <ErrorMessage name="workout" class="text-danger" />
                                            </div>
                                        </div>
                                        <div class="starting_plan mt-5 mb-5 workout-plan-evergreen" v-if="selectedAction.workout_plan.ids.length">
                                            <h4 class="mb-4">Choose a program assign method</h4>
                                            <div class="flex-space-between">
                                                <h5>Assign as evergreen <span class="workout-plan-badge">Easy to Scale</span></h5>
                                                <label for="start_w1_w2" class="switch_option capsule_btn">
                                                    <input type="radio" name="evergreen_option" id="start_w1_w2" :value="1"
                                                        v-model="selectedAction.workout_plan.is_evergreen" hidden>
                                                    <div><span></span></div>
                                                </label>
                                            </div>
                                            <p class="info-text mb-5">This workout program won't appear on your client's calendar. Instead, it will be available for on-demand completion in their programs until you set restrictions.</p>
                                            <div class="flex-space-between mt-3">
                                                <h5>Assign as date-based <span class="workout-plan-badge">Uses Calendar</span></h5>
                                                <label for="start_w1_w3" class="switch_option capsule_btn">
                                                    <input type="radio" name="evergreen_option" id="start_w1_w3" :value="0"
                                                        v-model="selectedAction.workout_plan.is_evergreen" hidden>
                                                    <div><span></span></div>
                                                </label>
                                            </div>
                                            <p class="info-text">This workout program will appear on your client's portal calendar and daily task list. This option also enabled client reminders.</p>
                                            <div class="mt-3" v-if="selectedAction.workout_plan.is_evergreen == 0">
                                                <div class="flex-space-between">
                                                    <h5>Start on Week 1 Workout 1? </h5>
                                                    <label for="start_w1_w1" class="switch_option capsule_btn">
                                                        <input type="checkbox" id="start_w1_w1" :true-value="1" :false-value="0"
                                                            v-model="selectedAction.workout_plan.start_type" hidden :disabled="selectedAction.workout_plan.ids.length > 1">
                                                        <div><span></span></div>
                                                    </label>
                                                </div>
                                                <div v-if="selectedAction.workout_plan.start_type">
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">Starting on:</label>
                                                            <div class="field_wpr">
                                                                <multiselect v-model="selectedAction.workout_plan.start_day" :options="startingDay"
                                                                    placeholder="Select one"></multiselect>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="extra_note" v-if="selectedAction.workout_plan.start_day == 0">
                                                        Workout plan will be started on Monday - {{ moment(selectedAction.workout_plan.start_date).format('ll') }}
                                                    </div>
                                                    <div class="start_plan" v-if="selectedAction.workout_plan.start_day == 1">
                                                        <date-picker v-model="selectedAction.workout_plan.start_date" :min-date='new Date()'
                                                            :disabled-dates="disabledDays" :first-day-of-week="2" is-expanded />
                                                    </div>
                                                </div>
                                                <div v-if="!selectedAction.workout_plan.start_type && selectedAction.workout_plan.ids.length == 1">
                                                    <div class="flex-space-between">
                                                        <h5>Start this week </h5>
                                                        <label for="start_this_week" class="switch_option capsule_btn">
                                                            <input type="checkbox" id="start_this_week" :true-value="1" :false-value="0"
                                                                v-model="selectedAction.workout_plan.start_on_this_week" hidden :disabled="selectedAction.workout_plan.ids.length > 1">
                                                            <div><span></span></div>
                                                        </label>
                                                    </div>
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">Select Starting Week</label>
                                                            <div class="field_wpr">
                                                                <multiselect v-model="selectedAction.workout_plan.start_week_id" :options="startingWeeks"
                                                                    placeholder="Select one" value-prop="id" label="name"></multiselect>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">Select Starting Workout</label>
                                                            <div class="field_wpr">
                                                                <multiselect v-model="selectedAction.workout_plan.start_workout_id" :options="startingWorkouts"
                                                                    placeholder="Select one" value-prop="id" label="name"></multiselect>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="flex-space-between mt-3" v-if="selectedAction.workout_plan.is_evergreen == 0">
                                                <label for="start_w1_w4" class="switch_option capsule_btn">
                                                    <h5>Show program in workout library</h5>
                                                    <input type="checkbox" name="evergreen_option" id="start_w1_w4" :true-value="1" :false-value="0"
                                                        v-model="selectedAction.workout_plan.show_in_library" hidden :disabled="selectedAction.workout_plan.ids.length > 1">
                                                    <div><span></span></div>
                                                </label>
                                            </div>
                                            <div class="additional-options">
                                                <h3><span>additional options</span></h3>
                                            </div>
                                            <div class="flex-space-between" v-show="selectedAction.workout_plan.ids.length">
                                                <h5>Add workouts to clients classroom. <span class="workout-plan-badge bagde-blue">Great Bonus</span></h5>
                                                <label for="assign-plan-workouts" class="switch_option capsule_btn">
                                                    <input type="checkbox" :true-value="1" :false-value="0" id="assign-plan-workouts" v-model="selectedAction.workout_plan.add_workouts_in_classroom" hidden>
                                                    <div><span></span></div>
                                                </label>
                                            </div>
                                            <p class="info-text mt-4">The individual workouts in this program will also be added to your client's workout library to be completed on demand.</p>
                                        </div>
                                    </div>
                                    <div class="edit_section" v-if="selectedAction.type == 37">
                                        <div class="form_grp mb-3">
                                            <div class="group_item">
                                                <label class="input_label">Select Workout Program</label>
                                                <div class="field_wpr" :class="{ 'has-error': errors.workout }">
                                                    <Field autocomplete="off" name="workout" v-model="selectedAction.workout_plan.ids" rules="required" label="workout">
                                                        <multiselect
                                                            v-model="selectedAction.workout_plan.ids"
                                                            label="name"
                                                            value-prop="id"
                                                            :searchable="true"
                                                            :options="workoutPlans"
                                                            placeholder="Select Workout Program"
                                                            mode="tags"
                                                        ></multiselect>
                                                    </Field>
                                                </div>
                                                <ErrorMessage name="workout" class="text-danger" />
                                            </div>
                                        </div>
                                        <label for="remove-plan-workouts" class="switch_option capsule_btn mt-5">
                                            <h5>Restrict these workouts in clients classroom.</h5>
                                            <input type="checkbox" :true-value="1" :false-value="0" id="remove-plan-workouts" v-model="selectedAction.workout_plan.remove_workouts_from_classroom" hidden>
                                            <div><span></span></div>
                                        </label>
                                    </div>
                                    <div class="edit_section" v-if="selectedAction.type == 38">
                                        <div class="form_grp mb-3">
                                            <div class="group_item">
                                                <label class="input_label">Add a file</label>
                                                <div class="field_wpr" :class="{ 'has-error': errors.file_id }">
                                                    <Field autocomplete="off" name="file_id" v-model="selectedAction.file_id" rules="required" label="file">
                                                        <multiselect
                                                            v-model="selectedAction.file_id"
                                                            label="name"
                                                            value-prop="id"
                                                            :searchable="true"
                                                            :options="files"
                                                            placeholder="Select file"
                                                            mode="tags"
                                                        >
                                                        <template v-slot:option="{ option }">
                                                            <img class="multiselect-img" :src="option.thumbnail" alt="img"> {{ option.name }}
                                                        </template>
                                                    </multiselect>
                                                    </Field>
                                                </div>
                                                <ErrorMessage name="file_id" class="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">Optional Note</label>
                                        <div class="field_wpr" :class="{ 'has-error': errors.title }">
                                            <Field autocomplete="off" name="title" type="text" placeholder="Add A Note" v-model="selectedAction.title" label="optional title" />
                                        </div>
                                        <ErrorMessage name="title" class="text-danger" />
                                    </div>
                                </div>
                            </div>

                            <!--Delay Form-->
                            <div class="setting_wpr" v-if="selectedDelayIndex != null">
                                <div class="form_grp" v-if="selectedAction.delay.type <= 5">
                                    <div class="group_item">
                                        <label class="input_label">Wait</label>
                                        <div class="field_wpr">
                                            <input type="number" min="0" v-model="selectedAction.delay.duration">
                                        </div>
                                    </div>
                                </div>
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label" v-if="selectedAction.delay.type > 5">Wait</label>
                                        <div class="field_wpr" :class="{ 'has-error': errors.delay_type }">
                                            <Field autocomplete="off" name="delay_type" v-model="selectedAction.delay.type" :rules="{ required: activeJourney.status == 1 }" label="delay type">
                                                <multiselect
                                                    v-model="selectedAction.delay.type"
                                                    :options="delayTypes"
                                                    placeholder="Select One.."
                                                ></multiselect>
                                            </Field>
                                        </div>
                                        <ErrorMessage name="delay_type" class="text-danger" />
                                    </div>
                                </div>
                                <div class="form_grp" v-if="selectedAction.delay.type == 9">
                                    <div class="group_item">
                                        <label class="input_label">Wait until current date of the year is</label>
                                        <div class="field_wpr" :class="{ 'has-error': errors.date }">
                                            <Field autocomplete="off" name="date" v-model="selectedAction.delay.date" :rules="{ required: activeJourney.status == 1 }">
                                                <datepicker v-model="selectedAction.delay.date" autoApply placeholder="MM/DD/YYYY" format="dd-MM-yyyy" :enableTimePicker="false" :min-date="minDate"></datepicker>
                                            </Field>
                                        </div>
                                        <ErrorMessage name="date" class="text-danger" />
                                    </div>
                                </div>
                                <div class="form_grp" v-if="selectedAction.delay.type == 7">
                                    <div class="group_item">
                                        <label class="input_label">What days of the week can we resume?</label>
                                        <ul class="day_listing">
                                            <li v-for="(week, w) of weekList" :key="w">
                                                <label :for="`day-${w}`" class="checkbox">
                                                    <input type="checkbox" :id="`day-${w}`" :true-value="1" :false-value="0" v-model="selectedAction.delay.weeks[week.name]" hidden>
                                                    <span><i class="fas fa-check"></i></span>
                                                    <p class="capitalize">{{ week.name }}</p>
                                                </label>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="form_grp" v-if="selectedAction.delay.type == 8">
                                    <div class="group_item">
                                        <label class="input_label" v-if="selectedAction.delay.type > 5">Wait</label>
                                        <div class="field_wpr">
                                            <multiselect
                                                v-model="selectedAction.delay.day"
                                                :options="months"
                                                placeholder="Select One.."
                                            ></multiselect>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="selectedAction.delay.type > 5">
                                    <div class="form_grp" >
                                        <div class="group_item">
                                            <label class="input_label" v-if="selectedAction.delay.type == 6">Wait until current time of the day is</label>
                                            <label class="input_label" v-else>Time</label>
                                            <div class="group-col-3">
                                                <div class="field_wpr">
                                                    <multiselect
                                                        v-model="selectedAction.delay.hour"
                                                        :options="hours"
                                                    ></multiselect>
                                                </div>
                                                <div class="field_wpr">
                                                    <multiselect
                                                        v-model="selectedAction.delay.minute"
                                                        :options="minutes"
                                                    ></multiselect>
                                                </div>
                                                <div class="field_wpr">
                                                    <multiselect
                                                        v-model="selectedAction.delay.clock"
                                                        :options="clocks"
                                                    ></multiselect>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form_grp" v-if="selectedAction.delay.type > 5">
                                        <div class="group_item">
                                            <label class="input_label">Timezone</label>
                                            <div class="field_wpr">
                                                <multiselect
                                                    v-model="selectedAction.delay.timezone"
                                                    :options="timezones"
                                                    valueProp="timezone"
                                                    label="timezone"
                                                    :searchable="true"
                                                    @select="handleTimezoneChange"
                                                >
                                                    <template v-slot:option="{ option }">
                                                        <span>{{ `(${ option.abbr} ${ option.utc_offset_str}) ${ option.timezone}` }}</span>
                                                    </template>
                                                </multiselect>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="extra_info mt-5" v-if="selectedAction.delay.type == 6">
                                        <p class="pb-20">
                                            Subscribers added before {{ twoDigitNumber(selectedAction.delay.hour) }}:{{ twoDigitNumber(selectedAction.delay.minute) }} {{ selectedAction.delay.clock }} will be triggered at {{ twoDigitNumber(selectedAction.delay.hour) }}:{{ twoDigitNumber(selectedAction.delay.minute) }} {{ selectedAction.delay.clock }} same day.
                                            <br/><br/>All subscribers added after {{ twoDigitNumber(selectedAction.delay.hour) }}:{{ twoDigitNumber(selectedAction.delay.minute) }} {{ selectedAction.delay.clock }} will be triggered at {{ twoDigitNumber(selectedAction.delay.hour) }}:{{ twoDigitNumber(selectedAction.delay.minute) }} {{ selectedAction.delay.clock }} the following day.
                                            <br />
                                            <span class="pull-right">Timezone: {{ selectedAction.delay.timezone }}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <!--Condition Form-->
                            <div class="setting_wpr" v-if="selectedConditionIndex != null">
                                  <label for="any_rule" class="switch_option capsule_btn">
                                      <h5>Any Rule<span>Select one or a few conditions where ANY rule can match the criteria</span></h5>
                                      <input type="radio" id="any_rule" :value="0" v-model="selectedAction.condition.rule_type" hidden>
                                      <div><span></span></div>
                                  </label>
                                  <label for="all_rule" class="switch_option capsule_btn">
                                      <h5>All Rules<span>Select one or a few conditions where ALL rules must match the criteria.</span></h5>
                                      <input type="radio" id="all_rule" :value="1" v-model="selectedAction.condition.rule_type" hidden>
                                      <div><span></span></div>
                                  </label>
                                  <div class="condition_wpr">
                                      <template v-for="(rule, r) of selectedAction.condition.rules" :key="r">
                                          <div class="edit_section mt-3 mb-3">
                                              <div class="form_grp">
                                                  <div class="group_item">
                                                      <label class="input_label">Condition</label>
                                                      <div class="field_wpr" :class="{ 'has-error': errors[`condition_${r}`] }">
                                                          <Field autocomplete="off" :name="`condition_${r}`" v-model="selectedAction.condition.rules[r].type" :rules="{ required: activeJourney.status == 1 }" label="condition">
                                                              <multiselect
                                                                  v-model="selectedAction.condition.rules[r].type"
                                                                  :options="conditions"
                                                                  placeholder="Select One.."
                                                              ></multiselect>
                                                          </Field>
                                                      </div>
                                                      <ErrorMessage :name="`condition_${r}`" class="text-danger" />
                                                  </div>
                                              </div>
                                              <div class="form_grp" v-if="selectedAction.condition.rules[r].type == 1">
                                                  <div class="group_item">
                                                      <div class="field_wpr" :class="{ 'has-error': errors[`tag_${r}`] }">
                                                          <Field autocomplete="off" :name="`tag_${r}`" v-model="selectedAction.condition.rules[r].type" :rules="{ required: activeJourney.status == 1 }" label="tag">
                                                              <multiselect
                                                                  v-model="selectedAction.condition.rules[r].tag"
                                                                  label="name"
                                                                  value-prop="id"
                                                                  :searchable="true"
                                                                  :group-select="true"
                                                                  :options="tagsGroup"
                                                                  :groups="true"
                                                                  group-label="type"
                                                                  group-options="tags"
                                                                  :group-hide-empty="true"
                                                                  placeholder="Select Tags"
                                                              >
                                                                  <template v-slot:grouplabel="{ group }">
                                                                      <span :class="group.type.replace(' ', '-').toLowerCase()">{{ group.type }}</span>
                                                                  </template>
                                                                  <template v-slot:option="{ option }">
                                                                      <span>{{ option.name }}</span>
                                                                  </template>
                                                                  <template v-slot:tag="{ option, handleTagRemove, disabled }">
                                                                      <div class="multiselect-tag" :class="option.type.replace(' ', '-').toLowerCase()">
                                                                        {{ option.name }}
                                                                        <span
                                                                            v-if="!disabled"
                                                                            class="multiselect-tag-remove"
                                                                            @mousedown.prevent="handleTagRemove(option, $event)"
                                                                        >
                                                                            <span class="multiselect-tag-remove-icon"></span>
                                                                        </span>
                                                                      </div>
                                                                  </template>
                                                              </multiselect>
                                                          </Field>
                                                      </div>
                                                      <ErrorMessage :name="`tag_${r}`" class="text-danger" />
                                                  </div>
                                              </div>
                                              <div class="form_grp" v-if="selectedAction.condition.rules[r].type == 2">
                                                  <div class="group_item">
                                                      <label class="input_label">Select group</label>
                                                      <div class="field_wpr" :class="{ 'has-error': errors[`group_${r}`] }">
                                                          <Field autocomplete="off" :name="`group_${r}`" v-model="selectedAction.condition.rules[r].type" :rules="{ required: activeJourney.status == 1 }" label="group">
                                                              <multiselect
                                                                  v-model="selectedAction.condition.rules[r].group"
                                                                  :searchable="true"
                                                                  :options="groups"
                                                                  valueProp="id"
                                                                  label="name"
                                                                  placeholder="Select group"
                                                              ></multiselect>
                                                          </Field>
                                                      </div>
                                                      <ErrorMessage :name="`group_${r}`" class="text-danger" />
                                                  </div>
                                              </div>
                                              <div class="form_grp" v-if="selectedAction.condition.rules[r].type == 3">
                                                  <div class="group_item">
                                                      <label class="input_label">Select segment</label>
                                                      <div class="field_wpr" :class="{ 'has-error': errors[`tab_${r}`] }">
                                                          <Field autocomplete="off" :name="`tab_${r}`" v-model="selectedAction.condition.rules[r].type" :rules="{ required: activeJourney.status == 1 }" label="tab">
                                                              <multiselect
                                                                  v-model="selectedAction.condition.rules[r].tab"
                                                                  :groups="true"
                                                                  :searchable="true"
                                                                  :options="statusesGroup"
                                                                  valueProp="value"
                                                                  label="title"
                                                                  placeholder="Select segment"
                                                                  :group-select="false"
                                                                  group-label="type"
                                                                  group-options="items"
                                                                  :group-hide-empty="true"
                                                              >
                                                                  <template v-slot:grouplabel="{ group }">
                                                                      <span class="status-tabs-header">{{ group.type }}</span>
                                                                  </template>
                                                                  <template v-slot:option="{ option }">
                                                                      <span>{{ option.title }}</span>
                                                                  </template>
                                                              </multiselect>
                                                          </Field>
                                                      </div>
                                                      <ErrorMessage :name="`tab_${r}`" class="text-danger" />
                                                  </div>
                                              </div>
                                              <button type="button" class="remove_btn pointer" @click="deleteCondition(r)"><i class="fas fa-trash-alt"></i></button>
                                          </div>
                                          <div class="divider" v-show="selectedAction.condition.rules.length != (r + 1)"><span>{{ selectedAction.condition.rule_type ? 'And' : 'Or' }}</span></div>
                                      </template>
                                  </div>
                                  <button type="button" class="add_btn pointer" @click="addNewCondition()"><i class="fas fa-plus"></i>Add Another Condition</button>
                              </div>
                        </div>
                    </div>
                    <div class="action_wpr">
                        <button :disabled="actionSaveLoader" type="button" class="btn cancel_btn" @click="closeTriggerActionPanel()">Cancel</button>
                        <button :disabled="actionSaveLoader" class="btn save_btn" id="save-action-button">
                            <i class="fa fa-spin fa-spinner" v-if="actionSaveLoader"></i> {{ actionSaveLoader ? 'Saving' : 'Save' }}
                        </button>
                    </div>
                </Form>
            </div>
        </div>
        <email-sms-preview v-model="fullPreviewMessage" :action="selectedAction" />
    </div>
    <div class="modal profile_modal share_playbook" v-if="showHistory">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <h1 class="m-0 pb-4">History log</h1>
                <button class="close_btn" @click="showHistory = false"><i class="fas fa-times"></i></button>
            </div>
            <div class="modal_body">
                <div class="tab_wpr mt-3">
                    <div class="result_wpr">
                        <div class="actions">
                            <ul>
                                <li class="optionDrops sort_list" @click="togglePerPageFilter()" v-click-outside="closePerPageDropdown">
                                    {{ journeyHistoryParams.per_page }} <i  class="fas fa-angle-down"></i>
                                    <page-filter v-model="journeyHistoryParams.per_page" :type="2" :is-dropdown="true" ref="journey-history-per-page-filter" />
                                </li>
                                <li></li>
                                <li class="list_info">
                                    {{ journeyHistories.from ? journeyHistories.from : 0 }} - {{ journeyHistories.to ? journeyHistories.to : 0 }} of <span>{{ journeyHistories.total ? journeyHistories.total : 0 }}</span>
                                </li>
                            </ul>
                        </div>
                        <div v-if="journeyHistoryLoader"><line-loader /></div>
                        <table class="standard">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>#</th>
                                    <th>Event</th>
                                </tr>
                            </thead>
                            <tbody v-if="journeyHistories.data && journeyHistories.data.length">
                                <tr v-for="(history, h) in journeyHistories.data" :key="h">
                                    <td></td>
                                    <td>{{ journeyHistories.from + h}}</td>
                                    <td v-html="history.event"></td>
                                </tr>
                            </tbody>
                            <tbody class="no_record" v-else>
                                <tr>
                                    <td colspan="3" class="px-4">No Records Found</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="pagination pb-4 mt-4" v-if="journeyHistories.data && journeyHistories.data.length">
                        <pagination v-model="journeyHistoryParams.page" :range-size="0" :pages="journeyHistories.last_page" @update:modelValue="handlePagination" />
                    </div>
                </div>
            </div>
            <div class="modal_footer">
                <button class="btn cancel_btn" @click="showHistory = false">Cancel</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'
    import { defineAsyncComponent } from 'vue'

    const EmailSmsPreview = defineAsyncComponent(() => import('@/pages/journey/components/EmailSmsPreview'))
    const PortalMessage = defineAsyncComponent(() => import('@/components/PortalMessage'))

    import Draggable from 'vuedraggable'
    import VueTagsInput from 'vue3-tags-input'
    import moment from 'moment-timezone'
    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'
    import { DatePicker } from 'v-calendar'

    export default {
        name: 'Journey Wizard New',

        data () {
            return {
                form: {
                    triggers: [
                        {
                            type: null,
                            sequence_id: 0,
                            tag_id: 0,
                            link_id: 0,
                            form_id: 0,
                            task_id: 0,
                            progress_tracking_id: 0,
                            habit_tracking_id: 0,
                        },
                    ],
                    actions: [],
                },
                loader: false,
                triggerLinks: [],
                selectedTriggerIndex: 0,
                triggerScale: 0,
                selectedActionIndex: null,
                selectedDelayIndex: null,
                selectedConditionIndex: null,
                statusArray: [],
                triggers: [
                    { value: 0, label: 'Subscribe to sequence' },
                    { value: 1, label: 'Completes a sequence' },
                    { value: 2, label: 'Tag added' },
                    { value: 3, label: 'Tag removed' },
                    { value: 4, label: 'Clicked the link' },
                    { value: 5, label: 'Submitted a form' },
                    // { value: 6, label: 'Assigned a task', disabled: true },
                    // { value: 7, label: 'Completed a task', disabled: true },
                    { value: 8, label: 'Submitted a client tracking report' },
                    { value: 9, label: 'Submitted a habit report' },
                ],
                selectedTriggerLink: { id: 0 },
                actions: [
                    { value: 0, label: 'Start a sequence' },
                    { value: 1, label: 'Stop a sequence' },
                    { value: 2, label: 'Add a tag' },
                    { value: 3, label: 'Remove a tag' },
                    { value: 4, label: 'Send a 3rd party notification' },
                    { value: 5, label: 'Assign a playbook' },
                    { value: 14, label: 'Restrict a playbook' },
                    { value: 6, label: 'Send to a page' },
                    { value: 27, label: 'Restrict a page in portal' },
                    { value: 7, label: 'Send an SMS' },
                    { value: 8, label: 'Send a template' },
                    { value: 9, label: 'Send a form or agreement' },
                    { value: 28, label: 'Restrict a form in portal' },
                    { value: 10, label: 'Send a single email' },
                    { value: 11, label: 'Add reward points' },
                    { value: 12, label: 'Terminate a journey' },
                    { value: 13, label: 'Switch contact between tabs' },
                    { value: 16, label: 'Add contact to a pipeline', disabled: true },
                    { value: 17, label: 'Switch pipeline stages', disabled: true },
                    { value: 18, label: 'Remove contact from a pipeline', disabled: true },
                    // { value: 19, label: 'Assign task to contact', disabled: true },
                    // { value: 20, label: 'Assign task to user', disabled: true },
                    // { value: 21, label: 'Unassign task from contact', disabled: true },
                    // { value: 22, label: 'Unassign task from user', disabled: true },
                    // { value: 23, label: 'Mark task complete', disabled: true },
                    // { value: 24, label: 'Mark task incomplete', disabled: true },
                    { value: 25, label: 'Start a client tracking' },
                    { value: 26, label: 'Stop a client tracking' },
                    { value: 29, label: 'Start a habit tracking' },
                    { value: 30, label: 'Stop a habit tracking' },
                    { value: 31, label: 'Send a portal message' },
                    { value: 32, label: 'Start a workout' },
                    { value: 33, label: 'Stop a workout' },
                    { value: 36, label: 'Enroll In Workout Program' },
                    { value: 37, label: 'Restrict Workout Program' },
                    { value: 34, label: 'Enable Broadcasts sending ' },
                    { value: 35, label: 'Disable Broadcasts sending' },
                    { value: 38, label: 'Add a file'},

                ],
                delayTypes: [
                    { value: 1, label: 'Minute(s)' },
                    { value: 2, label: 'Hour(s)' },
                    { value: 3, label: 'Day(s)' },
                    { value: 4, label: 'Week(s)' },
                    { value: 5, label: 'Month(s)' },
                    { value: 6, label: 'Specific time of the day' },
                    { value: 7, label: 'Specific day of the week' },
                    { value: 8, label: 'Specific day of the month' },
                    { value: 9, label: 'Specific day of the year' },
                ],
                journey: {},
                hours: [
                    { value: 0, label: '00' },
                    { value: 1, label: '01' },
                    { value: 2, label: '02' },
                    { value: 3, label: '03' },
                    { value: 4, label: '04' },
                    { value: 5, label: '05' },
                    { value: 6, label: '06' },
                    { value: 7, label: '07' },
                    { value: 8, label: '08' },
                    { value: 9, label: '09' },
                    { value: 10, label: '10' },
                    { value: 11, label: '11' },
                    { value: 12, label: '12' }
                ],
                minutes: [
                    { value: 0, label: '00' },
                    { value: 1, label: '01' },
                    { value: 2, label: '02' },
                    { value: 3, label: '03' },
                    { value: 4, label: '04' },
                    { value: 5, label: '05' },
                    { value: 6, label: '06' },
                    { value: 7, label: '07' },
                    { value: 8, label: '08' },
                    { value: 9, label: '09' },
                    { value: 10, label: '10' },
                    { value: 11, label: '11' },
                    { value: 12, label: '12' },
                    { value: 13, label: '13' },
                    { value: 14, label: '14' },
                    { value: 15, label: '15' },
                    { value: 16, label: '16' },
                    { value: 17, label: '17' },
                    { value: 18, label: '18' },
                    { value: 19, label: '19' },
                    { value: 20, label: '20' },
                    { value: 21, label: '21' },
                    { value: 22, label: '22' },
                    { value: 23, label: '23' },
                    { value: 24, label: '24' },
                    { value: 25, label: '25' },
                    { value: 26, label: '26' },
                    { value: 27, label: '27' },
                    { value: 28, label: '28' },
                    { value: 29, label: '29' },
                    { value: 30, label: '30' },
                    { value: 31, label: '31' },
                    { value: 32, label: '32' },
                    { value: 33, label: '33' },
                    { value: 34, label: '34' },
                    { value: 35, label: '35' },
                    { value: 36, label: '36' },
                    { value: 37, label: '37' },
                    { value: 38, label: '38' },
                    { value: 39, label: '39' },
                    { value: 40, label: '40' },
                    { value: 41, label: '41' },
                    { value: 42, label: '42' },
                    { value: 43, label: '43' },
                    { value: 44, label: '44' },
                    { value: 45, label: '45' },
                    { value: 46, label: '46' },
                    { value: 47, label: '47' },
                    { value: 48, label: '48' },
                    { value: 49, label: '49' },
                    { value: 50, label: '50' },
                    { value: 51, label: '51' },
                    { value: 52, label: '52' },
                    { value: 53, label: '53' },
                    { value: 54, label: '54' },
                    { value: 55, label: '55' },
                    { value: 56, label: '56' },
                    { value: 57, label: '57' },
                    { value: 58, label: '58' },
                    { value: 59, label: '59' },
                ],
                clocks: [
                    { value: 'am', label: 'AM' },
                    { value: 'pm', label: 'PM' }
                ],
                sequenceEmails: [],
                notificationEmail: {
                    defaultHandler: true,
                    subject: '',
                    email: '',
                    is_email_logo: 0,
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 0,
                    email_logo: '',
                },
                singleEmail: {
                    defaultHandler: true,
                    subject: '',
                    email: '',
                    is_email_logo: 0,
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 0,
                    email_logo: '',
                },
                selectStage: false,
                fullPreviewMessage: false,
                conditions: [
                    { value: 1, label: 'Has tag' },
                    { value: 2, label: 'Is in a group' },
                    { value: 3, label: 'Is in a contact center tab' },
                ],
                months: [
                    { value: 1, label: '1st day of the month' },
                    { value: 2, label: '2nd day of the month' },
                    { value: 3, label: '3rd day of the month' },
                    { value: 4, label: '4th day of the month' },
                    { value: 5, label: '5th day of the month' },
                    { value: 6, label: '6th day of the month' },
                    { value: 7, label: '7th day of the month' },
                    { value: 8, label: '8th day of the month' },
                    { value: 9, label: '9th day of the month' },
                    { value: 10, label: '10th day of the month' },
                    { value: 11, label: '11th day of the month' },
                    { value: 12, label: '12th day of the month' },
                    { value: 13, label: '13th day of the month' },
                    { value: 14, label: '14th day of the month' },
                    { value: 15, label: '15th day of the month' },
                    { value: 16, label: '16th day of the month' },
                    { value: 17, label: '17th day of the month' },
                    { value: 18, label: '18th day of the month' },
                    { value: 19, label: '19th day of the month' },
                    { value: 20, label: '20th day of the month' },
                    { value: 21, label: '21st day of the month' },
                    { value: 22, label: '22nd day of the month' },
                    { value: 23, label: '23rd day of the month' },
                    { value: 24, label: '24th day of the month' },
                    { value: 25, label: '25th day of the month' },
                    { value: 26, label: '26th day of the month' },
                    { value: 27, label: '27th day of the month' },
                    { value: 28, label: '28th day of the month' },
                    { value: 29, label: '29th day of the month (not available in all month)' },
                    { value: 30, label: '30th day of the month (not available in all month)' },
                    { value: 31, label: '31st day of the month (not available in all month)' },
                    { value: 0, label: 'Last day of the month' },
                ],
                selectedTrigger: {
                    type: null,
                    sequence_id: 0,
                    tag_id: 0,
                    link_id: 0,
                    form_id: 0,
                    task_id: 0,
                    progress_tracking_id: 0,
                    habit_tracking_id: 0,
                },
                selectedAction: {
                    type: null,
                    action_type: null,
                    sequence: null,
                    tag_id: null,
                    notification: null,
                    playbook_id: null,
                    page_id: null,
                    sms: null,
                    email: null,
                    template_id: null,
                    template_type: null,
                    template_category: null,
                    form_id: null,
                    rewards: null,
                    journey_id: null,
                    tab: null,
                    pipeline: null,
                    task: null,
                    progress_tracking_id: null,
                    habit_tracking_id: null,
                    workout_id: null,
                    workout_plan: {
                        ids: [],
                        start_type: 1,
                        start_day: 0,
                        start_date: '',
                        start_week_id: 0,
                        start_workout_id: 0,
                        add_workouts_in_classroom: 1,
                        remove_workouts_from_classroom: 1,
                        start_on_this_week: 0,
                        is_evergreen: 0,
                        show_in_library: 0,
                    },
                    file_id: null,
                    workout_settings: {
                        in_calendar: 0,
                        in_classroom: 1,
                        calendar_date: new Date().toISOString(),
                        calendar_hour: "7",
                        calendar_minute: "00",
                        calendar_ampm: "am",
                        timezone: this.user ? this.user.timezone : '',
                    },
                },
                notification_sms_media: [],
                sms_sms_media: [],
                isMounted: false,
                isAutosave: false,
                isTypingTrigger: false,
                isTypingAction: false,
                weekList: [
                    {
                        name: 'mon'
                    },
                    {
                        name: 'tue'
                    },
                    {
                        name: 'wed'
                    },
                    {
                        name: 'thu'
                    },
                    {
                        name: 'fri'
                    },
                    {
                        name: 'sat'
                    },
                    {
                        name: 'sun'
                    },
                ],
                minDate: new Date((new Date()).getTime() + 86400000),
                templateType: 'email',
                createTags: false,
                tagForm: {
                    name: '',
                    type: 'leads',
                    uniqueId: 'journey',
                },
                showHistory: false,
                journeyHistoryParams: {
                    page: 1,
                    journey_id: 0,
                    per_page: 5,
                },
                resolveCreateAction: null,
                highlightActionForm: false,
                activeJourney: {},
                portal_message_media: [],
                resetEmailComponent: false,
                startingDay: [
                    { value: 0, label: 'Start on next available Monday?' },
                    { value: 1, label: 'Start on a different Monday' },
                    { value: 2, label: 'Start this week' },
                ],
                startingWeeks: [],
                startingWorkouts: [],
                disabledDays: {
                    weekdays: [1, 3, 4, 5, 6, 7]
                },
                moment,
            };
        },

        props: {
            modelValue: Boolean,
        },

        emits: ['update:modelValue'],

        components: {
            Draggable,
            VueTagsInput,
            Field,
            Form,
            ErrorMessage,
            EmailSmsPreview,
            PortalMessage,
            DatePicker
        },

        watch: {
            modelValue (value) {
                const vm = this;

                const totalTrigger = vm.form.triggers.length;

                vm.triggerScale           = Math.round(totalTrigger / 2) - 1;
                vm.selectedTriggerIndex   = null;
                vm.selectedActionIndex    = null;
                vm.selectedDelayIndex     = null;
                vm.selectedConditionIndex = null;
                vm.selectedTriggerIndex   = vm.triggerScale;
                vm.templateType           = 'email';

                vm.journeyHistoryParams   = { page: 1, journey_id: vm.selectedJourney.id, per_page: 5 };

                if (value) {
                    vm.getDefinedLinks();
                    vm.refreshJourney();
                    vm.getJourneyHistory(vm.journeyHistoryParams);

                    const startDate = vm.getNextMonday();

                    if (vm.workoutPlans.length == 0) {
                        vm.getAllWorkoutPlans().then((result) => {
                            if (result) {
                                vm.selectedAction.workout_plan.start_date  = startDate;

                                if (vm.selectedAction.workout_plan.ids.length == 1) {
                                    vm.parseWeeks();
                                }
                            }
                        });
                    } else {
                        vm.selectedAction.workout_plan.start_date  = startDate;

                        if (vm.selectedAction.workout_plan.ids.length == 1) {
                            vm.parseWeeks();
                        }
                    }

                    if (vm.selectedAction.workout_plan.add_workouts_in_classroom == undefined) {
                        vm.selectedAction.workout_plan.add_workouts_in_classroom = 1;
                    }

                    if (vm.selectedAction.workout_plan.start_on_this_week == undefined) {
                        vm.selectedAction.workout_plan.start_on_this_week = 0;
                    }

                    if (vm.selectedAction.workout_plan.is_evergreen == undefined) {
                        vm.selectedAction.workout_plan.is_evergreen = 0;
                    }

                    if (vm.selectedAction.workout_plan.show_in_library == undefined) {
                        vm.selectedAction.workout_plan.show_in_library = 0;
                    }

                    if (vm.selectedAction.workout_plan.remove_workouts_from_classroom == undefined) {
                        vm.selectedAction.workout_plan.remove_workouts_from_classroom = 1;
                    }

                    if (vm.selectedAction.workout_settings && vm.selectedAction.workout_settings.in_calendar == undefined) {
                        vm.selectedAction.workout_settings = {
                            in_calendar: 0,
                            in_classroom: 1,
                            calendar_date: new Date().toISOString(),
                            calendar_hour: "7",
                            calendar_minute: "00",
                            calendar_ampm: "am",
                            timezone: vm.user.timezone,
                        };
                    }
                }

                setTimeout(function () {
                    vm.isMounted = true;
                }, 2000);
            },

            selectedAction: {
                handler (action) {
                    const vm = this;

                    if (action.type == 16 || action.type == 17) {
                        vm.getPipelineStages(action.pipeline.id);
                        vm.selectStage = true;
                    } else {
                        vm.selectStage = false;
                    }

                    if (vm.isMounted) {
                        vm.isTypingAction = true;

                        setTimeout(function () {
                            vm.isTypingAction = false;
                        }, 2000);
                    }

                    vm.showHistory = false;
                },
                deep: true
            },

            selectedTrigger: {
                handler (trigger) {
                    const vm = this;

                    if (vm.isMounted) {
                        vm.isTypingTrigger = true;

                        setTimeout(function () {
                            vm.isTypingTrigger = false;
                        }, 2000);
                    }

                    vm.showHistory = false;
                },
                deep:true,
            },

            isTypingAction (value) {
                const vm = this;

                if (!value && vm.isMounted) {
                    vm.isAutosave = true;
                    // document.getElementById('save-action-button').click();

                    setTimeout(function () {
                        vm.isAutosave = false;
                    }, 2000);
                }
            },

            isTypingTrigger (value) {
                const vm = this;

                if (!value && vm.isMounted) {
                    vm.isAutosave = true;
                    document.getElementById('save-trigger-button').click();

                    setTimeout(function () {
                        vm.isAutosave = false;
                    }, 2000);
                }
            },

            'selectedAction.type' (type) {
                const vm = this;

                if (type == 0) {
                    if (vm.selectedAction.sequence.id) {
                        vm.selectedAction.sequence.id = (typeof vm.selectedAction.sequence.id == 'object') ? vm.selectedAction.sequence.id[0] : vm.selectedAction.sequence.id;
                    } else {
                        vm.selectedAction.sequence.id = null;
                    }
                } else if (type == 1) {
                    if (vm.selectedAction.sequence.id) {
                        vm.selectedAction.sequence.id = (typeof vm.selectedAction.sequence.id == 'object') ? vm.selectedAction.sequence.id : vm.selectedAction.sequence.id.split(',');
                    } else {
                        vm.selectedAction.sequence.id = [];
                    }
                } else if (type == 16 || type == 17) {
                    if (vm.selectedAction.pipeline.id) {
                        vm.selectedAction.pipeline.id = (typeof vm.selectedAction.pipeline.id == 'object') ? vm.selectedAction.pipeline.id[0] : vm.selectedAction.pipeline.id;
                    } else {
                        vm.selectedAction.pipeline.id = null;
                    }
                } else if (type == 18) {
                    if (vm.selectedAction.pipeline.id) {
                        vm.selectedAction.pipeline.id = (typeof vm.selectedAction.pipeline.id == 'object') ? vm.selectedAction.pipeline.id : vm.selectedAction.pipeline.id.split(',');
                    } else {
                        vm.selectedAction.pipeline.id = [];
                    }
                } else if (type == 19 || type == 20 || type == 21 || type == 22) {
                    if (vm.selectedAction.task.id) {
                        vm.selectedAction.task.id = (typeof vm.selectedAction.task.id == 'object') ? vm.selectedAction.task.id[0] : vm.selectedAction.task.id;
                    } else {
                        vm.selectedAction.task.id = null;
                    }
                } else if (type == 23 || type == 24) {
                    if (vm.selectedAction.task.id) {
                        vm.selectedAction.task.id = (typeof vm.selectedAction.task.id == 'object') ? vm.selectedAction.task.id : vm.selectedAction.task.id.split(',');
                    } else {
                        vm.selectedAction.task.id = [];
                    }
                } else if (type == 32 && vm.selectedAction.workout_settings == undefined) {
                    vm.selectedAction.workout_settings = {
                                                            in_calendar: 0,
                                                            in_classroom: 1,
                                                            calendar_date: new Date().toISOString(),
                                                            calendar_hour: "7",
                                                            calendar_minute: "00",
                                                            calendar_ampm: "am",
                                                            timezone: vm.user.timezone,
                                                        };
                } else if (type == 32 && vm.selectedAction.workout_settings.in_calendar == undefined) {
                    vm.selectedAction.workout_settings = {
                                                            in_calendar: 0,
                                                            in_classroom: 1,
                                                            calendar_date: new Date().toISOString(),
                                                            calendar_hour: "7",
                                                            calendar_minute: "00",
                                                            calendar_ampm: "am",
                                                            timezone: vm.user.timezone,
                                                        };
                }
            },

            'selectedAction.template_category' (category) {
                const vm = this;

                vm.getTemplates({ category, type: vm.templateType ? vm.templateType : 'email' });
            },

            'selectedAction.workout_plan' (plan) {
                const vm = this;

                if (!plan.start_date && plan.start_day == 0) {
                    vm.selectedAction.workout_plan.start_date  = vm.getNextMonday();
                }

                if (vm.selectedAction.workout_plan.add_workouts_in_classroom == undefined) {
                    vm.selectedAction.workout_plan.add_workouts_in_classroom = 1;
                }

                if (vm.selectedAction.workout_plan.start_on_this_week == undefined) {
                    vm.selectedAction.workout_plan.start_on_this_week = 0;
                }

                if (vm.selectedAction.workout_plan.is_evergreen == undefined) {
                    vm.selectedAction.workout_plan.is_evergreen = 0;
                }

                if (vm.selectedAction.workout_plan.show_in_library == undefined) {
                    vm.selectedAction.workout_plan.show_in_library = 0;
                }

                if (vm.selectedAction.workout_plan.remove_workouts_from_classroom == undefined) {
                    vm.selectedAction.workout_plan.remove_workouts_from_classroom = 1;
                }
            },

            'selectedAction.workout_plan.start_week_id' (week) {
                const vm = this;

                vm.parseWorkouts(week);
            },

            'selectedAction.workout_plan.start_day' (day) {
                const vm = this;

                if (day == 0) {
                    vm.selectedAction.workout_plan.start_date  = vm.getNextMonday();
                }

                if (day == 2) {
                    vm.startOnThisWeekAlert();
                }
            },

            'selectedAction.workout_plan.ids' (ids) {
                const vm = this;

                if (ids.length == 1) {
                    vm.parseWeeks();
                }
            },

            'journeyHistoryParams.per_page' () {
                const vm = this;

                vm.journeyHistoryParams.page = 1;
                vm.getJourneyHistory(vm.journeyHistoryParams);
            },

            highlightActionForm (value) {
                if (value) {
                    const vm = this;

                    setTimeout(function () {
                        vm.highlightActionForm = false;
                    }, 2000);
                }
            },

            'selectedAction.sequence.id' (id) {
                const vm = this;

                if (vm.selectedAction.type == 0) {
                    vm.resetSequenceOrders(id);
                }
            },

            'selectedAction.workout_plan.start_on_this_week' (val) {
                 const vm = this;

                if (val)  {
                    vm.startOnThisWeekAlert();
                }
            },

            fullPreviewMessage (val) {
                const vm = this;

                vm.selectedAction.notification.subject         = vm.notificationEmail.subject;
                vm.selectedAction.notification.email           = vm.notificationEmail.email;
                vm.selectedAction.notification.is_signature    = vm.notificationEmail.is_signature;
                vm.selectedAction.notification.signature_id    = vm.notificationEmail.signature_id;
                vm.selectedAction.notification.show_email_logo = vm.notificationEmail.show_email_logo;
                vm.selectedAction.notification.email_logo      = vm.notificationEmail.email_logo;
                vm.selectedAction.email.subject                = vm.singleEmail.subject;
                vm.selectedAction.email.email                  = vm.singleEmail.email;
                vm.selectedAction.email.is_signature           = vm.singleEmail.is_signature;
                vm.selectedAction.email.signature_id           = vm.singleEmail.signature_id;
                vm.selectedAction.email.show_email_logo        = vm.singleEmail.show_email_logo;
                vm.selectedAction.email.email_logo             = vm.singleEmail.email_logo;
                vm.selectedAction.notification.sms_media       = vm.notification_sms_media;
                vm.selectedAction.sms.media                    = vm.sms_sms_media;
                vm.selectedAction.portal_message.media         = vm.portal_message_media;
            },

            selectedJourney (journey) {
                const vm = this;

                vm.activeJourney = JSON.parse(JSON.stringify(journey));
            },

            links: {
                handler (links) {
                    const vm = this;
                    const triggerLinks = [];

                    vm.links.forEach((link) => {
                        if (link.label != 'Trigger Links') {
                            triggerLinks.push(link);
                        }
                    });

                    vm.triggerLinks = triggerLinks;
                },
                deep: true
            },
        },

        computed: mapState({
            user: state => state.authModule.user,
            playbooks: state => state.playbookModule.allPlaybooks,
            pages: state => state.pageModule.allPages,
            forms: state => state.formModule.allForms,
            journeys: state => state.journeyModule.allJourneys,
            tagsGroup: state => state.tagModule.tagsGroup,
            tags: state => state.tagModule.tags,
            pipelines: state => state.pipelineModule.allPipelines,
            sequences: state => state.sequenceModule.allSequences,
            progressTrackings: state => state.progressTrackingModule.allProgressTrackings,
            habitTrackings: state => state.habitTrackingModule.allHabitTrackings,
            tasks: state => state.taskModule.allTasks,
            userTasks: state => state.taskModule.allUserTasks,
            journeyLinks: state => state.journeyModule.journeyLinks,
            templates: state => state.templateModule.templates,
            allTemplates: state => state.templateModule.allTemplates,
            templateCategories: state => state.templateModule.categories,
            templateAjaxLoader: state => state.templateModule.templateAjaxLoader,
            statuses: state => state.contactModule.statusList,
            statusesGroup: state => state.contactModule.statusesGroup,
            links: state => state.commonModule.definedLinks,
            timezones: state => state.commonModule.timezones,
            pipelineStages: state => state.pipelineModule.pipelineStages,
            organizationUsers: state => state.authModule.organizationUsers,
            selectedJourney: state => state.journeyModule.selectedJourney,
            journeyAddTriggerActionLoader: state => state.journeyModule.journeyAddTriggerActionLoader,
            triggerSaveLoader: state => state.journeyModule.triggerSaveLoader,
            actionSaveLoader: state => state.journeyModule.actionSaveLoader,
            groups: state => state.groupModule.groups,
            tagLoader: state => state.tagModule.tagLoader,
            journeyHistoryLoader: state => state.journeyModule.journeyHistoryLoader,
            journeyHistories: state => state.journeyModule.journeyHistories,
            workouts: state => state.workoutModule.allWorkouts,
            workoutPlans: state => state.workoutModule.allWorkoutPlans,
            files: state => state.fileModule.allFiles,
        }),

        mounted () {
            const vm = this;
            const startDate = vm.getNextMonday();

            setTimeout(function () {
                if (vm.tagsGroup.length == 0) {
                    vm.getTags();
                }

                if (vm.pipelines.length == 0) {
                    vm.getAllPipelines({ select: 'id,name' });
                }

                if (vm.sequences.length == 0) {
                    vm.getAllSequences({ select: 'id,name' });
                }

                if (vm.progressTrackings.length == 0) {
                    vm.getAllProgressTrackings({ select: 'id,name' });
                }

                if (vm.habitTrackings.length == 0) {
                    vm.getAllHabitTrackings({ select: 'id,name' });
                }

                if (vm.tasks.length == 0) {
                    vm.getAllTasks({ select: 'id,name' });
                }

                if (vm.userTasks.length == 0) {
                    vm.getAllUserTasks({ select: 'id,name' });
                }

                if (vm.playbooks.length == 0) {
                    vm.getAllPlaybooks({ select: 'id,title' });
                }

                if (vm.pages.length == 0) {
                    vm.getAllPages({ select: 'id,title,is_private' });
                }

                if (vm.forms.length == 0) {
                    vm.getAllForms({ select: 'id,survey_name,is_private' });
                }

                if (vm.journeys.length == 0) {
                    vm.getAllJourneys({ select: 'id,name,action,action_title,action_type' });
                }

                if (vm.allTemplates.length == 0) {
                    vm.getAllTemplates({ select: 'id,title' });
                }

                if (vm.statuses.length == 0) {
                    vm.getStatuses();
                }

                if (vm.timezones.length == 0) {
                    vm.getTimezones();
                }

                if (vm.organizationUsers.length == 0) {
                    vm.getOrganizationUsers();
                }

                if (vm.templateCategories.length == 0) {
                    vm.getTemplateCategories();
                }

                if (vm.groups.length == 0) {
                    vm.getGroups();
                }

                if (vm.workouts.length == 0) {
                    vm.getAllWorkouts({ select: 'id,name' });
                }

                if (vm.files.length == 0) {
                    vm.getAllFiles({ select: 'id, title' });
                }

                if (vm.workoutPlans.length == 0) {
                    vm.getAllWorkoutPlans().then((result) => {
                        if (result) {
                            vm.selectedAction.workout_plan.start_date  = startDate;

                            if (vm.selectedAction.workout_plan.ids.length == 1) {
                                vm.parseWeeks();
                            }
                        }
                    });
                } else {
                    vm.selectedAction.workout_plan.start_date  = startDate;

                    if (vm.selectedAction.workout_plan.ids.length == 1) {
                        vm.parseWeeks();
                    }
                }

                vm.parseStatusTitle(vm.statuses);
                vm.getJourneyLinks({ select: 'id,name,url' });

                const triggerLinks = [];

                vm.links.forEach((link) => {
                    if (link.label != 'Trigger Links') {
                        triggerLinks.push(link);
                    }
                });

                vm.triggerLinks = triggerLinks;
            }, 1500);

            window.addEventListener('resize', vm.resizeEventHandler);
            vm.resizeEventHandler();
        },

        unmounted() {
            const vm = this;
            window.removeEventListener('resize', vm.resizeEventHandler);
        },

        methods: {
            ...mapActions({
                getTags: 'tagModule/getTags',
                getAllPipelines: 'pipelineModule/getAllPipelines',
                getAllSequences: 'sequenceModule/getAllSequences',
                getAllProgressTrackings: 'progressTrackingModule/getAllProgressTrackings',
                getAllHabitTrackings: 'habitTrackingModule/getAllHabitTrackings',
                getAllTasks: 'taskModule/getAllTasks',
                getAllUserTasks: 'taskModule/getAllUserTasks',
                getAllPlaybooks: 'playbookModule/getAllPlaybooks',
                getAllPages: 'pageModule/getAllPages',
                getAllForms: 'formModule/getAllForms',
                getAllJourneys: 'journeyModule/getAllJourneys',
                getJourneyLinks: 'journeyModule/getJourneyLinks',
                getAllTemplates: 'templateModule/getAllTemplates',
                getTemplates: 'templateModule/getTemplates',
                getTemplateCategories: 'templateModule/getCategories',
                getStatuses: 'contactModule/getStatuses',
                getTimezones: 'commonModule/getTimezones',
                getPipelineStages: 'pipelineModule/getPipelineStages',
                getOrganizationUsers: 'authModule/getOrganizationUsers',
                createTrigger: 'journeyModule/createTrigger',
                createAction: 'journeyModule/createAction',
                journeyDetails: 'journeyModule/journeyDetails',
                saveTrigger: 'journeyModule/saveTrigger',
                saveAction: 'journeyModule/saveAction',
                deleteTrigger: 'journeyModule/deleteTrigger',
                deleteAction: 'journeyModule/deleteAction',
                getGroups: 'groupModule/getGroups',
                createTag: 'tagModule/createTag',
                getJourneyHistory: 'journeyModule/getJourneyHistory',
                getDefinedLinks: 'commonModule/getDefinedLinks',
                refreshAction: 'journeyModule/refreshAction',
                changeJourneyStatus: 'journeyModule/changeJourneyStatus',
                getAllWorkouts: 'workoutModule/getAllWorkouts',
                getAllWorkoutPlans: 'workoutModule/getAllWorkoutPlans',
                getAllFiles: 'fileModule/getAllFiles',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
                vm.isMounted = false;
            },

            comingSoonAlert (type) {
                const options = Helper.swalWarningOptions('Coming Soon!', `This ${type} is coming soon in 2023!`, 'Okay');

                Swal.fire(options);
            },

            parseStatusTitle (statuses) {
                const vm = this;

                for (let index in statuses) {
                    vm.statusArray[statuses[index].value] = statuses[index].title;
                }
            },

            triggerTitle (type) {
                const data = [];
                data[0] = 'Contact subscribe to a sequence';
                data[1] = 'Contact completes a sequence';
                data[2] = 'Tag is added to a contact';
                data[3] = 'Tag is removed from a contact';
                data[4] = 'Contact clicks a link';
                data[5] = 'Contact submits a form';
                data[6] = 'Task assigned to contact';
                data[7] = 'Task completed by contact';
                data[8] = 'client tracking submitted by contact';
                data[9] = 'Habits submitted by contact';

                return data[type] ? data[type] : 'Set Trigger';
            },

            triggerName (trigger) {
                const vm = this;

                try {
                    if (trigger.type == 0 || trigger.type == 1) {
                        return vm.sequenceNameById(trigger.sequence_id);
                    } else if (trigger.type == 2 || trigger.type == 3) {
                        return vm.tagNameById(trigger.tag_id);
                    } else if (trigger.type == 5) {
                        return vm.formNameById(trigger.form_id);
                    } else if (trigger.type == 6 || trigger.type == 7) {
                        return vm.taskNameById(trigger.task_id);
                    } else if (trigger.type == 8) {
                        return vm.progressNameById(trigger.progress_tracking_id);
                    } else if (trigger.type == 9) {
                        return vm.habitNameById(trigger.habit_tracking_id);
                    }
                } catch (error) {
                    return null;
                }
            },

            actionName (action) {
                const vm = this;
                let assets = [];

                try {
                    if (action.type == 0) {
                        assets = [vm.sequenceNameById(action.sequence.id)];
                    } else if (action.type == 1) {
                        action.sequence.id.forEach((asset) => {
                            assets.push(vm.sequenceNameById(asset));
                        })
                    } else if (action.type == 2 || action.type == 3) {
                        action.tag_id.forEach((asset) => {
                            assets.push(vm.tagNameById(asset));
                        })
                    } else if (action.type == 5 || action.type == 14) {
                        action.playbook_id.forEach((asset) => {
                            assets.push(vm.playbookNameById(asset));
                        })
                    } else if (action.type == 6 || action.type == 27) {
                        action.page_id.forEach((asset) => {
                            assets.push(vm.pageNameById(asset));
                        })
                    } else if (action.type == 7) {
                        return action.sms.content ? action.sms.content : '';
                    } else if (action.type == 8) {
                        action.template_id.forEach((asset) => {
                            assets.push(vm.templateNameById(asset));
                        })
                    } else if (action.type == 9 || action.type == 28) {
                        action.form_id.forEach((asset) => {
                            assets.push(vm.formNameById(asset));
                        })
                    } else if (action.type == 11) {
                        return `You have earned <span class="action-chip">${action.rewards.points}</span> points for <span class="action-chip">${action.rewards.reward_reason}</span>`;
                    } else if (action.type == 12) {
                        action.journey_id.forEach((asset) => {
                            assets.push(vm.journeyNameById(asset));
                        })
                    } else if (action.type == 13) {
                        return [Helper.statusName(action.tab, vm.statuses)];
                    } else if (action.type == 16 || action.type == 17) {
                        const pipeline = vm.pipelineNameById(action.pipeline.id);
                        const stage = vm.pipelineStageNameById(action.pipeline.id, action.pipeline.stage_id);

                        if (pipeline || stage) {
                            return { pipeline, stage };
                        }

                        return null;
                    } else if (action.type == 18) {
                        action.pipeline.id.forEach((asset) => {
                            assets.push(vm.pipelineNameById(asset));
                        })
                    } else if (action.type == 19 || action.type == 21) {
                        assets = [vm.taskNameById(action.task.id)];
                    } else if (action.type == 20 || action.type == 22) {
                        const task = vm.userTaskNameById(action.task.id);
                        const users = [];

                        vm.organizationUsers.forEach((user) => {
                            if (action.task.users.includes(user.id)) {
                                users.push(user.full_name);
                            }
                        });

                        if (task || users) {
                            return { task, users };
                        }

                        return null;
                    } else if (action.type == 23 || action.type == 24) {
                        action.task.id.forEach((asset) => {
                            assets.push(vm.taskNameById(asset));
                        })
                    } else if (action.type == 25 || action.type == 26) {
                        action.progress_tracking_id.forEach((asset) => {
                            assets.push(vm.progressNameById(asset));
                        })
                    } else if (action.type == 29 || action.type == 30) {
                        action.habit_tracking_id.forEach((asset) => {
                            assets.push(vm.habitNameById(asset));
                        })
                    } else if (action.type == 31) {
                        return action.portal_message.content ? action.portal_message.content : '';
                    } else if (action.type == 32 || action.type == 33) {
                        action.workout_id.forEach((asset) => {
                            assets.push(vm.workoutNameById(asset));
                        })
                    } else if (action.type == 36 || action.type == 37) {
                        action.workout_plan.ids.forEach((asset) => {
                            assets.push(vm.workoutPlanNameById(asset));
                        })
                     } else if (action.type == 38) {
                        action.file_id.forEach((asset) => {
                            assets.push(vm.fileNameById(asset));
                        })
                    }

                    return assets.filter((asset) => asset);
                } catch (error) {
                    return [];
                }
            },

            playbookNameById (id) {
                const vm = this;
                const index = vm.playbooks.findIndex(x => x.id == id);

                if (index == -1) {
                    return null;
                }

                return vm.playbooks[index].title;
            },

            pageNameById (id) {
                const vm = this;
                const index = vm.pages.findIndex(x => x.id == id);

                if (index == -1) {
                    return null;
                }

                return vm.pages[index].title;
            },

            templateNameById (id) {
                const vm = this;
                const index = vm.allTemplates.findIndex(x => x.id == id);

                if (index == -1) {
                    return null;
                }

                return vm.allTemplates[index].title;
            },

            pipelineNameById (id) {
                const vm = this;
                const index = vm.pipelines.findIndex(x => x.id == id);

                if (index == -1) {
                    return null;
                }

                return vm.pipelines[index].title;
            },

            pipelineStageNameById (id, stageId) {
                const vm = this;
                const index = vm.pipelines.findIndex(x => x.id == id);

                if (index == -1) {
                    return null;
                }

                const sindex = vm.pipelines[index].stages.findIndex(x => x.id == stageId);

                if (sindex == -1) {
                    return null;
                }

                return vm.pipelines[index].stages[sindex].name;
            },

            journeyNameById (id) {
                const vm = this;
                const index = vm.journeys.findIndex(x => x.id == id);

                if (index == -1) {
                    return null;
                }

                return vm.journeys[index].name;
            },

            sequenceNameById (id) {
                const vm = this;
                const index = vm.sequences.findIndex(x => x.id == id);

                if (index == -1) {
                    return null;
                }

                return vm.sequences[index].name;
            },

            tagNameById (id) {
                const vm = this;
                const index = vm.tags.findIndex(x => x.id == id);

                if (index == -1) {
                    return null;
                }

                return vm.tags[index].name;
            },

            formNameById (id) {
                const vm = this;
                const index = vm.forms.findIndex(x => x.id == id);

                if (index == -1) {
                    return null;
                }

                return vm.forms[index].survey_name;
            },

            taskNameById (id) {
                const vm = this;
                const index = vm.tasks.findIndex(x => x.id == id);

                if (index == -1) {
                    return null;
                }

                return vm.tasks[index].name;
            },

            userTaskNameById (id) {
                const vm = this;
                const index = vm.userTasks.findIndex(x => x.id == id);

                if (index == -1) {
                    return null;
                }

                return vm.userTasks[index].name;
            },

            progressNameById (id) {
                const vm = this;
                const index = vm.progressTrackings.findIndex(x => x.id == id);

                if (index == -1) {
                    return null;
                }

                return vm.progressTrackings[index].name;
            },

            habitNameById (id) {
                const vm = this;
                const index = vm.habitTrackings.findIndex(x => x.id == id);

                if (index == -1) {
                    return null;
                }

                return vm.habitTrackings[index].name;
            },

            workoutNameById (id) {
                const vm = this;
                const index = vm.workouts.findIndex(x => x.id == id);

                if (index == -1) {
                    return null;
                }

                return vm.workouts[index].name;
            },

            workoutPlanNameById (id) {
                const vm = this;
                const index = vm.workoutPlans.findIndex(x => x.id == id);

                if (index == -1) {
                    return null;
                }

                return vm.workoutPlans[index].name;
            },

            fileNameById (id) {
                const vm = this;
                const index = vm.files.findIndex(x => x.id == id);

                if (index == -1) {
                    return null;
                }

                return vm.files[index].name;
            },

            triggerUrlHtml (trigger) {
                const vm    = this;
                let html    = '';
                let link     = {};
                const index = vm.journeyLinks.findIndex(x => x.id == trigger.link_id);

                if (index != -1) {
                    link = vm.journeyLinks[index];
                }

                if (link.name) {
                    html += `<p>${ link.name }</p>`
                }

                if (link.url) {
                    html += `<a>${ link.url }</a>`
                }

                if (!link.name && !link.url) {
                    html += `<h4>Define Trigger</h4>`
                }

                return html;
            },

            addAction (e) {
                const vm = this;
                let isTriggerSet = false;
                const incompleteActions = document.getElementsByClassName('incomplete-action');

                if (incompleteActions.length && vm.selectedJourney.status) {
                    const options = Helper.swalWarningOptions('Oops!', 'Please update or delete the incomplete step before proceeding');

                    Swal.fire(options);

                    incompleteActions.forEach((action) => {
                        action.classList.add('invalid-action');
                    });
                } else {
                    for (let t = 0; t < vm.form.triggers.length; t++) {
                        const trigger = vm.form.triggers[t];
                        if (trigger.type !== null && (trigger.sequence_id || trigger.tag_id || trigger.link_id || trigger.form_id || trigger.task_id || trigger.progress_tracking_id || trigger.habit_tracking_id)) {
                            isTriggerSet = true;
                            break;
                        }
                    }

                    if (isTriggerSet || vm.activeJourney.status == 0) {
                        let elm       = e.currentTarget.parentNode;
                        let allelm    = document.querySelectorAll('.step_type');
                        let elPos     = elm.getBoundingClientRect();
                        let scrHeight = screen.height;

                        if (elm.classList.contains('active')) {
                            elm.classList.remove('active');
                            elm.style.zIndex = '1';
                        } else {
                            for (let i = 0; i < allelm.length; i++) {
                                allelm[i].classList.remove('active');
                                allelm[i].classList.remove('top');
                                allelm[i].style.zIndex = '1';
                            }

                            if (elPos.y > (scrHeight - 400)) {
                                elm.classList.add('top');
                            }

                            elm.classList.add('active');
                            elm.style.zIndex = '2';
                        }

                        if (vm.selectedActionIndex || vm.selectedDelayIndex || vm.selectedConditionIndex) {
                            // document.getElementById('save-action-button').click();
                        }
                    } else {
                        const options = Helper.swalWarningOptions('', 'Please define the trigger first!');

                        Swal.fire(options);
                    }
                }
            },

            addTrigger (order) {
                const vm = this;

                const trigger = {
                                    type: null,
                                    sequence_id: null,
                                    tag_id: null,
                                    link_id: null,
                                    form_id: null,
                                    task_id: null,
                                    progress_tracking_id: null,
                                    habit_tracking_id: null,
                                    order: null,
                                    automation_id: vm.selectedJourney.id,
                                };

                if (order == 0) {
                    trigger.order = 0;
                }

                vm.createTrigger(trigger).then((trigger) => {
                    if (trigger) {
                        vm.refreshJourney();

                        vm.isMounted = false;

                        setTimeout(function () {
                            vm.isMounted = true;
                        }, 2000);

                        vm.selectedActionIndex    = null;
                        vm.selectedDelayIndex     = null;
                        vm.selectedConditionIndex = null;
                        vm.selectedTriggerIndex   = trigger.id;
                        vm.selectedTrigger = trigger;

                        setTimeout(function () {
                            document.getElementById(`trigger-box-${trigger.id}`).click();
                        }, 2000);
                    }
                });
            },

            selectTrigger (id, trigger) {
                const vm = this;

                vm.isMounted = false;

                setTimeout(function () {
                    vm.isMounted = true;
                }, 2000);

                vm.selectedActionIndex    = null;
                vm.selectedDelayIndex     = null;
                vm.selectedConditionIndex = null;
                vm.showHistory            = false;
                vm.selectedTriggerIndex   = id;
                vm.selectedTrigger        = trigger;

                if (trigger.type == 4) {
                    setTimeout(function () {
                        let link     = {};
                        const idx = vm.journeyLinks.findIndex(x => x.id == trigger.link_id);

                        if (idx != -1) {
                            link = vm.journeyLinks[idx];
                        }

                        const triggerLink = new URL(link.url);

                        for (let i = 0; i < vm.triggerLinks.length; i++) {
                              for (let j = 0; j < vm.triggerLinks[i].options.length; j++) {
                                  if (triggerLink.pathname == vm.slugifyLink(vm.triggerLinks[i].options[j])) {
                                      const origin = (new URL(process.env.VUE_APP_API_URL)).origin;
                                      vm.selectedTriggerLink = JSON.parse(JSON.stringify(vm.triggerLinks[i].options[j]));
                                      vm.selectedTriggerLink.public_url = origin + vm.slugifyLink(vm.triggerLinks[i].options[j]);
                                      break;
                                  }
                              }
                        }
                    }, 300);
                }
            },

            slugifyLink (link) {
                const vm = this;
                let sluggedUrl = '';

                if (link.asset_type == 'forms') {
                    sluggedUrl = '/survey/' + link.id + '/' +  vm.slugify(link.title);
                } else if (link.asset_type == 'playbooks') {
                    sluggedUrl = '/playbook/login/' + link.id + '/' + vm.slugify(link.title);
                } else if (link.asset_type == 'pages') {
                    sluggedUrl = '/page/' + link.id + '-' + vm.slugify(link.title);
                } else {
                    sluggedUrl = link.destination;
                }

                return sluggedUrl;
            },

            slugify (str) {
                let string = str;
                const from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;";
                const to   = "aaaaaeeeeeiiiiooooouuuunc------";

                str = str.replace(/^\s+|\s+$/g, '');
                str = str.toLowerCase();

                for (let i = 0, l = from.length; i < l; i++) {
                    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
                }

                str = str.replace(/[^a-z0-9 -]/g, '').replace(/\s+/g, '-').replace(/-+/g, '-');

                return str;
            },

            closeTriggerActionPanel () {
                const vm = this;

                vm.selectedTriggerIndex   = null;
                vm.selectedActionIndex    = null;
                vm.selectedDelayIndex     = null;
                vm.selectedConditionIndex = null;
            },

            selectAction (id, type, action) {
                const vm = this;

                vm.isMounted = false;

                setTimeout(function () {
                    vm.isMounted = true;
                }, 2000);

                vm.selectedTriggerIndex         = null;
                vm.selectedActionIndex          = null;
                vm.selectedDelayIndex           = null;
                vm.selectedConditionIndex       = null;
                vm.showHistory                  = false;
                vm.selectedAction               = action;
                vm.templateType                 = action.template_type ? action.template_type : 'email';
                vm.selectedAction.template_type = action.template_type ? action.template_type : 'email';

                vm.notificationEmail.subject          = vm.selectedAction.notification.subject;
                vm.notificationEmail.email            = vm.selectedAction.notification.email;
                vm.notificationEmail.is_signature     = vm.selectedAction.notification.is_signature;
                vm.notificationEmail.signature_id     = vm.selectedAction.notification.signature_id;
                vm.notificationEmail.show_email_logo  = vm.selectedAction.notification.show_email_logo;
                vm.notificationEmail.is_email_logo    = vm.selectedAction.notification.show_email_logo;
                vm.notificationEmail.email_logo       = vm.selectedAction.notification.email_logo;
                vm.singleEmail.subject                = vm.selectedAction.email.subject;
                vm.singleEmail.email                  = vm.selectedAction.email.email;
                vm.singleEmail.is_signature           = vm.selectedAction.email.is_signature;
                vm.singleEmail.signature_id           = vm.selectedAction.email.signature_id;
                vm.singleEmail.show_email_logo        = vm.selectedAction.email.show_email_logo;
                vm.singleEmail.is_email_logo          = vm.selectedAction.email.show_email_logo;
                vm.singleEmail.email_logo             = vm.selectedAction.email.email_logo;
                vm.notification_sms_media             = vm.selectedAction.notification.sms_media;
                vm.sms_sms_media                      = vm.selectedAction.sms.media;
                vm.portal_message_media               = vm.selectedAction.portal_message.media;

                vm.resetEmailComponent = true;

                setTimeout(function () {
                    vm.resetEmailComponent = false;
                }, 100);

                if (type == 'action') {
                    vm.selectedActionIndex    = id;
                } else if (type == 'delay') {
                    vm.selectedDelayIndex     = id;
                } else if (type == 'condition') {
                    vm.selectedConditionIndex = id;
                }

                if (action.type == 0) {
                    vm.resetSequenceOrders(action.sequence.id);
                }
            },

            actionTitle (code) {
                const data = [];
                data[0] = 'Start a sequence';
                data[1] = 'Stop a sequence';
                data[2] = 'Add a tag';
                data[3] = 'Remove a tag';
                data[4] = 'Notify 3rd party';
                data[5] = 'Assign a playbook';
                data[6] = 'Send to a page';
                data[7] = 'Send an SMS';
                data[8] = 'Send a template';
                data[9] = 'Send a form or agreement';
                data[10] = 'Send a single email';
                data[11] = 'Add reward points';
                data[12] = 'Terminate a journey';
                data[13] = 'Switch tabs';
                data[14] = 'Restrict a playbook';
                data[15] = 'Assign contacts to users';
                data[16] = 'Add contact to a pipeline';
                data[17] = 'Switch pipeline stages';
                data[18] = 'Remove contact from a pipeline';
                data[19] = 'Assign task to contact';
                data[20] = 'Assign task to user';
                data[21] = 'Unassign task from contact';
                data[22] = 'Unassign task from user';
                data[23] = 'Mark task complete';
                data[24] = 'Mark task incomplete';
                data[25] = 'Start a client tracking';
                data[26] = 'Stop a client tracking';
                data[27] = 'Restrict a page in portal';
                data[28] = 'Restrict a form in portal';
                data[29] = 'Start a habit tracking';
                data[30] = 'Stop a habit tracking';
                data[31] = 'Send a portal message';
                data[32] = 'Start a workout';
                data[33] = 'Stop a workout';
                data[36] = 'Enroll In Workout Program';
                data[37] = 'Restrict Workout Program';
                data[34] = 'Enable Broadcasts sending';
                data[35] = 'Disable Broadcasts sending';
                data[38] = 'Add a file';

                return data[code] ? data[code] : 'This action is blank';
            },

            addNewAction (order, action_type, parent_id = 0, with_true_condition = 0, actions) {
                const vm = this;

                return new Promise(function(resolve, reject) {
                    const conditionWrapper = document.querySelectorAll('.condition-container');

                    if (action_type == 'condition' && conditionWrapper && conditionWrapper.length >= 5) {
                        const options = Helper.swalWarningOptions('Condition limit reached', 'You can no longer add further conditions to this journey since the condition limit has been reached.')

                        Swal.fire(options)
                    } else {
                        const action = {
                                            id: 0,
                                            type: null,
                                            action_type,
                                            sequence: { id: null, msg_start_one: 1, email_order: 0, throttle_tz: 0 },
                                            tag_id: [],
                                            notification: {
                                                emails: [vm.user.email],
                                                numbers: [vm.user.companyno],
                                                sending_method: 1,
                                                sms_content: 'Hi {{company_owner}},\r\n\r\n This message was configured as a third-party notification in your {{journey_name}} journey and needs to be updated with your goals. \r\n\r\n Cheers! \r\n Thrive Coach',
                                                sms_media: [],
                                                subject: 'Journey Alert',
                                                email: 'Hi {{company_owner}},<br /><br />This message was configured as a third-party notification in your {{journey_name}} journey and needs to be updated with your goals.<br /><br />Cheers!<br />Thrive Coach',
                                                is_signature: 0,
                                                signature_id: 0,
                                                show_email_logo: 0,
                                                email_logo: '',
                                            },
                                            playbook_id: [],
                                            sending_method: 1,
                                            page_id: [],
                                            sms: { content: '',  media: [],  throttle_tz: 0 },
                                            email: {
                                                subject: '',
                                                email: '',
                                                is_signature: 0,
                                                signature_id: 0,
                                                show_email_logo: 0,
                                                email_logo: '',
                                            },
                                            template_id: [],
                                            template_category: null,
                                            form_id: [],
                                            rewards: { points: 0, notification: 0, sending_method: 1, reward_reason: '' },
                                            journey_id: [],
                                            tab: null,
                                            pipeline: { id: null, stage_id: null },
                                            task: { id: null, users: [] },
                                            progress_tracking_id: [],
                                            habit_tracking_id: [],
                                            delay: {
                                                type: null,
                                                duration: 0,
                                                weeks: {
                                                    sun: 0,
                                                    mon: 0,
                                                    tue: 0,
                                                    wed: 0,
                                                    thu: 0,
                                                    fri: 0,
                                                    sat: 0,
                                                },
                                                hour: 7,
                                                minute: 0,
                                                clock: 'am',
                                                day: 1,
                                                date: '',
                                                timezone: vm.user.timezone,
                                            },
                                            condition: {
                                                rule_type: 0,
                                                rules: [{
                                                    type: '',
                                                    tag: '',
                                                    tab: '',
                                                    group: '',
                                                }]
                                            },
                                            true_actions: [],
                                            false_actions: [],
                                            automation_id: vm.selectedJourney.id,
                                            order,
                                            parent_id,
                                            with_true_condition,
                                            template_type: 'email',
                                            portal_message: { content: '',  media: [] },
                                            workout_id: [],
                                            workout_plan: {
                                                ids: [],
                                                start_type: 1,
                                                start_day: 0,
                                                start_date: '',
                                                start_week_id: 0,
                                                start_workout_id: 0,
                                                add_workouts_in_classroom: 1,
                                                remove_workouts_from_classroom: 1,
                                                start_on_this_week: 0,
                                                is_evergreen: 0,
                                                show_in_library: 0,
                                            },
                                            file_id: [],
                                        };

                        vm.templateType                       = action.template_type ? action.template_type : 'email';
                        vm.notificationEmail.subject          = action.notification.subject;
                        vm.notificationEmail.email            = action.notification.email;
                        vm.notificationEmail.is_signature     = action.notification.is_signature;
                        vm.notificationEmail.signature_id     = action.notification.signature_id;
                        vm.notificationEmail.show_email_logo  = action.notification.show_email_logo;
                        vm.notificationEmail.is_email_logo    = action.notification.show_email_logo;
                        vm.notificationEmail.email_logo       = action.notification.email_logo;
                        vm.singleEmail.subject                = action.email.subject;
                        vm.singleEmail.email                  = action.email.email;
                        vm.singleEmail.is_signature           = action.email.is_signature;
                        vm.singleEmail.signature_id           = action.email.signature_id;
                        vm.singleEmail.show_email_logo        = action.email.show_email_logo;
                        vm.singleEmail.is_email_logo          = action.email.show_email_logo;
                        vm.singleEmail.email_logo             = action.email.email_logo;
                        vm.notification_sms_media             = action.notification.sms_media;
                        vm.sms_sms_media                      = action.sms.media;
                        vm.portal_message_media               = action.portal_message.media;

                        let allelm = document.querySelectorAll('.step_type');

                        for (let i = 0; i < allelm.length; i++) {
                            allelm[i].classList.remove('active');
                            allelm[i].style.zIndex = '1';
                        }

                        // if (actions != undefined && action_type == 'condition' && actions.filter((a) => a.action_type == 'condition').length) {
                        if (actions != undefined && action_type == 'condition' && actions.length && order < actions.length) {
                            const options = {
                                title: '',
                                text: 'Where should we move the remaining journeys steps to?',
                                iconHtml: `<img src="${require('@/assets/images/alert.svg')}" alt="" class="alert_img">`,
                                showCancelButton: true,
                                focusConfirm: false,
                                confirmButtonText: '<i class="fas fa-thumbs-up"></i>',
                                confirmButtonAriaLabel: 'Thumbs up',
                                cancelButtonText: '<i class="fas fa-thumbs-down"></i>',
                                cancelButtonAriaLabel: 'Thumbs down',
                                confirmButtonColor: '#2f7fed',
                                cancelButtonColor: '#f2a31e',
                                allowOutsideClick: false,
                            };

                            Swal.fire(options).then((result) => {
                                action.make_parent = true;

                                if (result.isConfirmed) {
                                    action.moved_under_true   = true;
                                    vm.createAction(action).then((resp) => {
                                        if (resp) {
                                            vm.refreshJourney();

                                            setTimeout(function () {
                                                vm.selectAction(resp.id, resp.action_type, resp);
                                            }, 700);

                                            setTimeout(function () {
                                                const box = document.getElementById(`action-box-${resp.id}`);

                                                if (box) {
                                                    box.click();
                                                }
                                            }, 2000);
                                        }

                                        resolve(true);
                                    });
                                } else if (result.isDismissed) {
                                    action.moved_under_true   = false;
                                    vm.createAction(action).then((resp) => {
                                        if (resp) {
                                            vm.refreshJourney();

                                            setTimeout(function () {
                                                vm.selectAction(resp.id, resp.action_type, resp);
                                            }, 700);

                                            setTimeout(function () {
                                                const box = document.getElementById(`action-box-${resp.id}`)

                                                if (box) {
                                                    box.click();
                                                }
                                            }, 2000);
                                        }

                                        resolve(true);
                                    });
                                }
                            });
                        } else {
                            vm.createAction(action).then((resp) => {
                                if (resp) {
                                    vm.refreshJourney();

                                    setTimeout(function () {
                                        vm.selectAction(resp.id, resp.action_type, resp);
                                    }, 700);

                                    setTimeout(function () {
                                        const box = document.getElementById(`action-box-${resp.id}`);

                                        if (box) {
                                            box.click();
                                        }
                                    }, 2000);
                                }

                                resolve(true);
                            });
                        }
                    }
                });
            },

            delayTitle (delay) {
                const vm      = this;
                let delayText = 'Set Delay';

                if (delay.type == 1) {
                    delayText = `Wait ${delay.duration} ${delay.duration > 1 ? 'Minute(s)' : 'Minute'}`;
                } else if (delay.type == 2) {
                    delayText = `Wait ${delay.duration} ${delay.duration > 1 ? 'Hour(s)' : 'Hour'}`;
                } else if (delay.type == 3) {
                    delayText = `Wait ${delay.duration} ${delay.duration > 1 ? 'Day(s)' : 'Day'}`;
                } else if (delay.type == 4) {
                    delayText = `Wait ${delay.duration} ${delay.duration > 1 ? 'Week(s)' : 'Week'}`;
                } else if (delay.type == 5) {
                    delayText = `Wait ${delay.duration} ${delay.duration > 1 ? 'Month(s)' : 'Month'}`;
                } else if (delay.type == 6) {
                    delayText = `Wait until ${Helper.twoDigitNumber(delay.hour)}:${Helper.twoDigitNumber(delay.minute)} ${delay.clock.toUpperCase()}`;
                } else if (delay.type == 7) {
                    let weeks = '<ul class="capitalize">';

                    vm.weekList.forEach((week) => {
                        if (delay.weeks[week.name] == 1) {
                            weeks += `<li><span>${week.name}</span></li>`;
                        }
                    });

                    weeks += '</ul>';

                    delayText = `Wait until current day of week is: ${weeks} at ${Helper.twoDigitNumber(delay.hour)}:${Helper.twoDigitNumber(delay.minute)} ${delay.clock.toUpperCase()}`;
                } else if (delay.type == 8) {
                    delayText = `Wait until ${delay.day ? Helper.ordinalSuffix(delay.day): 'last'} day of current month at ${Helper.twoDigitNumber(delay.hour)}:${Helper.twoDigitNumber(delay.minute)} ${delay.clock.toUpperCase()}`;
                } else if (delay.type == 9) {
                    delayText = `Wait until ${moment(delay.date).format('ll')} at ${Helper.twoDigitNumber(delay.hour)}:${Helper.twoDigitNumber(delay.minute)} ${delay.clock.toUpperCase()}`;
                }

                return delayText;
            },

            refreshJourney () {
                const vm = this;

                vm.loader = true;

                vm.journeyDetails(vm.selectedJourney.id).then((journey) => {
                    if (journey) {
                        vm.form = journey;

                        const totalTrigger = vm.form.triggers.length;

                        vm.triggerScale     = Math.round(totalTrigger / 2) - 1;

                        if (journey.triggers[vm.triggerScale]) {
                            vm.isMounted = false;

                            vm.selectedTrigger  = journey.triggers[vm.triggerScale];

                            if (!vm.selectedActionIndex && !vm.selectedDelayIndex && !vm.selectedConditionIndex) {
                                vm.selectedTriggerIndex = vm.selectedTrigger.id;
                            }

                            setTimeout(function () {
                                vm.isMounted = true;
                            }, 2000);
                        }
                    }

                    setTimeout(function () {
                        vm.loader = false;
                    }, 10);
                });
            },

            handleDeleteTrigger (id) {
                const vm = this;

                if (vm.selectedJourney.members_count) {
                    const options = Helper.swalConfirmOptions('Are you sure?', `This action is permanent. You will not be able to recover this trigger.`, 'Delete');

                    options.preConfirm = function () {
                                            return vm.deleteTrigger(id).then((result) => {
                                                vm.refreshJourney();
                                                vm.getTags();
                                                vm.selectedTriggerIndex   = null;
                                                vm.selectedActionIndex    = null;
                                                vm.selectedDelayIndex     = null;
                                                vm.selectedConditionIndex = null;
                                            });
                                        };

                    Swal.fire(options);
                } else {
                    vm.deleteTrigger(id).then((result) => {
                        vm.refreshJourney();
                        vm.getTags();
                        vm.selectedTriggerIndex   = null;
                        vm.selectedActionIndex    = null;
                        vm.selectedDelayIndex     = null;
                        vm.selectedConditionIndex = null;
                    });
                }

            },

            handleDeleteAction (action, type) {
                const vm = this;

                return vm.refreshAction(action.id).then((result) => {
                    return new Promise(function(resolve, reject) {
                        let options = {};

                        if (type == 'condition' && (action.true_actions_count || action.false_actions_count || (result && (result.true_actions_count || result.false_actions_count)))) {
                            let inputOptions = {
                                all: 'Delete both branches including all steps below.',
                                yes: 'Delete only YES branch including all steps below.',
                                no: 'Delete only NO branch including all steps below.',
                            };

                            options = {
                                title: 'Are you sure?',
                                html: 'This action is permanent. You will not be able to recover this condition.',
                                confirmButtonText: 'Delete',
                                iconHtml: `<img src="${require('@/assets/images/alert.svg')}" alt="" class="alert_img">`,
                                confirmButtonColor: '#2f80ed',
                                showCancelButton: true,
                                allowOutsideClick: false,
                                showLoaderOnConfirm: true,
                                input: 'radio',
                                inputOptions,
                                inputValidator: (value) => {
                                    if (!value) {
                                        return 'Please select at least one option!'
                                    }
                                },
                            };

                            options.preConfirm = function (value) {
                                                    return vm.deleteAction({ id: action.id, type: (value == 'all' || value == 'yes' || value == 'no') ? value : null }).then((result) => {
                                                        vm.refreshJourney();
                                                        vm.selectedTriggerIndex   = null;
                                                        vm.selectedActionIndex    = null;
                                                        vm.selectedDelayIndex     = null;
                                                        vm.selectedConditionIndex = null;
                                                        resolve(true);
                                                    });
                                                };

                            Swal.fire(options).then((result) => {
                                if (result.isConfirmed || result.isDismissed) {
                                    resolve(true);
                                }
                            });
                        } else {
                            if (vm.selectedJourney.members_count) {
                                options = Helper.swalConfirmOptions('Are you sure?', `This action is permanent. You will not be able to recover this ${type}.`, 'Delete');

                                options.preConfirm = function (value) {
                                                        return vm.deleteAction({ id: action.id, type: (value == 'all' || value == 'yes' || value == 'no') ? value : null }).then((result) => {
                                                            vm.refreshJourney();
                                                            vm.selectedTriggerIndex   = null;
                                                            vm.selectedActionIndex    = null;
                                                            vm.selectedDelayIndex     = null;
                                                            vm.selectedConditionIndex = null;
                                                            resolve(true);
                                                        });
                                                    };

                                Swal.fire(options).then((result) => {
                                    if (result.isConfirmed || result.isDismissed) {
                                        resolve(true);
                                    }
                                });
                            } else {
                                vm.deleteAction({ id: action.id, type: null }).then((result) => {
                                    vm.refreshJourney();
                                    vm.selectedTriggerIndex   = null;
                                    vm.selectedActionIndex    = null;
                                    vm.selectedDelayIndex     = null;
                                    vm.selectedConditionIndex = null;
                                    resolve(true);
                                });
                            }
                        }
                    });
                });
            },

            deleteCondition (index) {
                const vm = this;

                vm.selectedAction.condition.rules.splice(index, 1);
            },

            addNewCondition () {
                const vm = this;

                vm.selectedAction.condition.rules.push({type: '', tag: '', tab: '', group: '' });
            },

            handleSubmitTriggerForm (form, { setFieldError }) {
                const vm = this;
                const params = vm.selectedTrigger;
                params.setFieldError  = setFieldError;

                if (vm.selectedTrigger.type == 4) {
                    params.title          = vm.selectedTriggerLink.title
                    params.public_url     = vm.selectedTriggerLink.public_url
                }

                vm.saveTrigger(params).then((result) => {
                    if (result) {
                        vm.isMounted = false;
                        vm.refreshJourney();

                        if (vm.selectedTrigger.type == 4) {
                            vm.getJourneyLinks({ select: 'id,name,url' });
                            vm.getDefinedLinks();
                        }

                        if (vm.selectedTrigger.type == 2 || vm.selectedTrigger.type == 3) {
                            vm.getTags();
                        }

                        vm.selectedTriggerLink = { id: 0 };

                        setTimeout(function () {
                            vm.isMounted = true;
                        }, 2000);
                    }
                });
            },

            handleSubmitActionForm (form, { setFieldError }) {
                const vm = this;
                const params = vm.selectedAction;

                params.setFieldError = setFieldError;

                if (params.type == 32) {
                    if (vm.selectedAction.workout_settings && !vm.selectedAction.workout_settings.in_classroom && !vm.selectedAction.workout_settings.in_calendar) {
                        const option = Helper.swalWarningOptions('Oops!', `Please select at least one option, either in the classroom or on the calendar. `, 'Okay');

                        Swal.fire(option);
                        return;
                    }
                }

                params.notification.subject         = vm.notificationEmail.subject;
                params.notification.email           = vm.notificationEmail.email;
                params.notification.is_signature    = vm.notificationEmail.is_signature;
                params.notification.signature_id    = vm.notificationEmail.signature_id;
                params.notification.show_email_logo = vm.notificationEmail.show_email_logo;
                params.notification.email_logo      = vm.notificationEmail.email_logo;
                params.email.subject                = vm.singleEmail.subject;
                params.email.email                  = vm.singleEmail.email;
                params.email.is_signature           = vm.singleEmail.is_signature;
                params.email.signature_id           = vm.singleEmail.signature_id;
                params.email.show_email_logo        = vm.singleEmail.show_email_logo;
                params.email.email_logo             = vm.singleEmail.email_logo;
                params.notification.sms_media       = vm.notification_sms_media;
                params.sms.media                    = vm.sms_sms_media;
                params.portal_message.media         = vm.portal_message_media;

                if (params.delay && params.delay.date) {
                    params.delay.date = moment(params.delay.date).format('YYYY-MM-DD');
                }

                if (params.type == 32) {
                    params.workout_settings.calendar_date = moment(params.workout_settings.calendar_date).format('YYYY-MM-DD');
                }

                if (params.id) {
                    vm.saveAction(params).then((result) => {
                        if (result) {
                            vm.isMounted = false;
                            vm.refreshJourney();

                            setTimeout(function () {
                                vm.isMounted = true;
                            }, 2000);

                            const el = document.getElementById(`step-${params.id}`);

                            if (el) {
                                el.classList.remove('incomplete-action');
                                el.classList.remove('invalid-action');
                            }
                        }
                    });
                } else {
                    vm.createAction(params).then((resp) => {
                        if (resp) {
                            vm.refreshJourney();

                            setTimeout(function () {
                                vm.selectAction(resp.id, resp.action_type, resp);
                            }, 700);

                            setTimeout(function () {
                                const box = document.getElementById(`action-box-${resp.id}`);

                                if (box) {
                                    box.click();
                                }
                            }, 1000);
                        }

                        if (vm.resolveCreateAction) {
                            vm.resolveCreateAction(true);
                            vm.resolveCreateAction = null;
                        }
                    });
                }
            },

            handleEmailRecipientUpdate (recipients) {
                const vm = this;

                vm.selectedAction.notification.emails = recipients;
            },

            handleSmsRecipientUpdate (recipients) {
                const vm = this;

                vm.selectedAction.notification.numbers = recipients;
            },

            twoDigitNumber (number) {
                return Helper.twoDigitNumber(number);
            },

            selectTemplate (e, type) {
                const vm = this;
                let temp = e.currentTarget;
                let allTemp = temp.closest('.template_list').querySelectorAll('.card_item');

                vm.templateType = type;
                vm.selectedAction.template_type = type;

                for (let i = 0; i < allTemp.length; i++) {
                    allTemp[i].classList.remove('active');
                }

                if (temp.classList.contains('active')) {
                    temp.classList.remove('active');
                } else {
                    temp.classList.add('active');
                }

                if (vm.selectedAction.template_category) {
                    vm.getTemplates({ category: vm.selectedAction.template_category, type });
                }
            },

            handleTimezoneChange (timezone) {
                const vm = this;
                const timezones = vm.form.timezones;

                if ((timezones.length > 1 && timezones.includes(timezones)) || (timezones.length && !timezones.includes(timezone))) {
                    const options = Helper.swalWarningOptions('', 'Some delays in this journey have different time zones. Update to avoid delivery issues.');

                    Swal.fire(options);
                }
            },

            handleTriggerLinkChange (e, el) {
                const vm = this;
                const origin = (new URL(process.env.VUE_APP_API_URL)).origin;

                vm.selectedTriggerLink.title = el.title;
                vm.selectedTriggerLink.public_url = origin + vm.slugifyLink(el);
            },

            resetSequenceOrders (sequenceId) {
                const vm = this;
                const sequence = vm.sequences.find(sequence => sequence.id == sequenceId);

                vm.sequenceEmails = [];

                for (let i = 0; i < sequence.publish_messages; i++) {
                    vm.sequenceEmails.push({ name: `Published Message ${(1 + i)}`, order : i });
                }
            },

            handleDefault3rdPartyEmailNotification () {
                const vm = this;

                vm.notificationEmail.subject  = 'Journey Alert';
                vm.notificationEmail.email    = 'Hi {{company_owner}},<br /><br />This message was configured as a third-party notification in your {{journey_name}} journey and needs to be updated with your goals.<br /><br />Cheers!<br />Thrive Coach';
            },

            handleDefault3rdPartySMSNotification () {
                const vm = this;

                vm.selectedAction.notification.sms_content    = 'Hi {{company_owner}},\r\n\r\n This message was configured as a third-party notification in your {{journey_name}} journey and needs to be updated with your goals. \r\n\r\n Cheers! \r\n Thrive Coach';
            },

            handleTagCreate () {
                const vm = this;
                const form = vm.$refs['tag-form'];

                form.validate().then(({ valid }) => {
                    if (valid) {
                        vm.tagForm.setFieldError = form.setFieldError;

                        vm.createTag(vm.tagForm).then((result) => {
                            vm.createTags = false;
                            vm.tagForm.name = '';
                            vm.getTags();
                        });
                    }
                });
            },

            toggleHistoryPanel () {
                const vm = this;

                vm.showHistory            = !vm.showHistory;
                // vm.selectedTriggerIndex   = null;
                // vm.selectedActionIndex    = null;
                // vm.selectedDelayIndex     = null;
                // vm.selectedConditionIndex = null;
            },

            togglePerPageFilter () {
                const vm = this;

                const filter = vm.$refs['journey-history-per-page-filter'];

                if (filter) {
                    filter.dropdown = !filter.dropdown;
                }
            },

            closePerPageDropdown () {
                const vm = this;

                const filter = vm.$refs['journey-history-per-page-filter'];

                if (filter) {
                    filter.dropdown = false;
                }
            },

            handlePagination (page) {
                const vm = this;

                vm.journeyHistoryParams.page = page;
                vm.getJourneyHistory(vm.journeyHistoryParams);
            },

            handleJourneyStatus (journey) {
                const vm = this;
                const incompleteActions = document.getElementsByClassName('incomplete-action');

                if (incompleteActions.length && journey.status) {
                    const options = Helper.swalWarningOptions('Oops!', `This journey has ${incompleteActions.length} incomplete ${incompleteActions.length > 1 ? 'actions' : 'action'}, please complete ${incompleteActions.length > 1 ? 'all' : 'the'} incomplete ${incompleteActions.length > 1 ? 'actions' : 'action'} to enable this journey.`);

                    Swal.fire(options)

                    vm.activeJourney.status = journey.status ? 0 : 1;

                    incompleteActions.forEach((action) => {
                        action.classList.add('invalid-action');
                    });
                } else {
                    const options = Helper.swalConfirmOptions('Are you sure?', `You want to ${ journey.status ? 'enable' : 'disable' } this journey.`);

                    options.preConfirm = function () {
                        return vm.changeJourneyStatus({ id: journey.id, status: journey.status });
                    };

                    Swal.fire(options).then((result) => {
                        if (!result.isConfirmed) {
                            vm.activeJourney.status = journey.status ? 0 : 1;
                        }
                    });
                }
            },

            resizeEventHandler () {
                const vm = this;
                let screenWidth = window.innerWidth;

                if (screenWidth < 768) {
                    vm.selectedTriggerIndex   = null;
                    vm.selectedActionIndex    = null;
                    vm.selectedDelayIndex     = null;
                    vm.selectedConditionIndex = null
                } else {
                    vm.selectedTriggerIndex = 0;
                }
            },

            toggleContent (e) {
                let el = e.currentTarget;

                if (el.classList.contains('more')) {
                    el.classList.remove('more');
                } else {
                    el.classList.add('more');
                }
            },

            getNextMonday(date = new Date()) {
                return moment(date).tz('UTC').add(1, 'weeks').isoWeekday(1);

                // const dateCopy = new Date(date.getTime());

                // const nextMonday = new Date(
                //     dateCopy.setDate(
                //         dateCopy.getDate() + ((7 - dateCopy.getDay() + 1) % 7 || 7),
                //     ),
                // );

                // return nextMonday;
            },

            parseWeeks () {
                const vm = this;
                const plan = vm.workoutPlans.filter(plan => plan.id == vm.selectedAction.workout_plan.ids[0]);

                vm.startingWeeks = plan && plan[0] ? plan[0].weeks : [];
            },

            parseWorkouts (id) {
                const vm        = this;
                const week      = vm.startingWeeks.filter(w => w.id == id);
                const workouts  = [];

                if (week && week[0] && week[0].week_settings) {
                    week[0].week_settings.forEach((setting) => {
                        setting.workouts.forEach((workout) => {
                            workouts.push({
                                id: workout.workout_id + '_' + setting.day,
                                name: setting.title.substring(0, 3) + ' - ' + workout.name + ' (' + workout.period + ')'
                            });
                        });
                    });
                }

                vm.startingWorkouts = workouts;
            },

            startOnThisWeekAlert () {
                const vm = this;

                if (vm.isMounted) {
                    const option = Helper.swalWarningOptions('Alert!', `By starting the plan this week, selected contact will get the plan assigned to their calendar this week. If the plan starts on a Monday and today is Wednesday, then it will show as missed they will need to complete it late. This won't affect their tracking, but it will show as completed late.`, 'Okay')

                    Swal.fire(option);
                }
            }
        },
    }
</script>

<style scoped>
    .multiselect-tag {
        padding: .125rem .5rem;
    }

    .trigger_wpr ul {
        display: flex;
        position: relative;
    }
    .trigger_wpr ul:before {
        content: '';
        position: absolute;
        left: 50%;
        top: 55px;
        bottom: 0;
        border-left: 1px solid #e9e9e9;
        z-index: -1;
    }
    .trigger_wpr > ul > li {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .trigcard_wpr{
        display: flex;
        gap: 50px;
        position: relative;
        padding: 0 40px;
    }

    .trigger_wpr .trigcard_wpr:after {
        content: '';
        position: absolute;
        left: 0;
        top: 55px;
        right: 0;
        border-top: 1px solid #e9e9e9;
        z-index: -1;
    }

    .global_setting .tabs_content form>.action_wpr {
        padding: 25px 40px;
    }

    .forms-setting-form {
        display: flex;
        flex-direction: column;
        height: inherit;
    }

    .global_setting .tabs_content {
        background: #fff;
        flex: 0 1 450px;
        position: relative;
        margin-right: -450px;
        transition: all 0.5s ease-in-out;
        z-index: 3;
    }

    .global_setting .tabs_content.show {
        margin-right: 0;
    }

    .global_setting .tabs_content .close_btn {
        position: absolute;
        top: 27px;
        left: 10px;
    }

    .global_setting .tabs_content .action_header {
        border: 1px solid #e9e9e9;
    }
    .journey_wpr{
        overflow: hidden;
    }
    .journey_wpr .step_details {
        padding: 25px 40px;
    }

    .journey_wpr .left_bar {
        padding: 30px 25px 30px 40px;
        border: 1px solid #eee;
        height: 100vh;
        overflow-y: scroll;
        position: relative;
        z-index: 2;
        background: #fafafb;
        flex: 1;
    }

    .journey_wpr .left_bar::-webkit-scrollbar {
        width: 4px;
        display: block;
    }

    .journey_wpr .left_bar::-webkit-scrollbar-thumb {
        background-color: #dfdfdf;
        border-radius: 2px;
    }

    .trigger_wpr {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: fit-content;
        margin: 0 auto;
        position: relative;
        padding: 0 40px;
        /* min-width: 1320px; */
    }

    .trigger_wpr .trigger_card {
        /* position: relative; */
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 0 55px 0;
    }

    .trigger_info {
        border: 1px solid #e9e9e9;
        background: #fff;
        position: relative;
        overflow: hidden;
        border-radius: 8px;
        width: 260px;
        position: relative;
    }

    .trigger_info .icon {
        position: absolute;
        left: 15px;
        top: 10px;
        font-size: 14px;
        color: #999;
    }

    .trigger_info.active {
        border: 2px solid #bad7ff;
    }

    .trigger_info h5 {
        flex: 1;
        text-align: center;
        font-size: 13px;
        line-height: 18px;
        font-weight: 500;
        color: #5a5a5a;
        padding: 10px 30px;
        border-bottom: 1px solid #e9e9e9;
    }

    .trigger_info .info {
        flex: 1;
        padding: 20px 15px;
        margin: 0;
        display: flex;
        justify-content: center;
        text-align: center;
    }

    .trigger_info .info h4 {
        border: 1px dashed #c9c9c9;
        padding: 7px 10px;
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        color: #5a5a5a;
        min-width: 200px;
    }

    .trigger_info .info h6 {
        font-size: 11px;
        line-height: 15px;
        font-weight: 500;
        color: #5a5a5a;
    }

    :deep(.trigger_info .info p) {
        margin: 5px 0;
        min-width: 200px;
        font-size: 15px;
        line-height: 22px;
        font-weight: 500;
        color: #121525;
    }

    :deep(.trigger_info .info a) {
        font-size: 11px;
        line-height: 15px;
        font-weight: 500;
        color: #448aed;
    }

    .trigger_info .info ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 4px;
        /* margin-top: 10px; */
        margin: 5px 0;
    }

    .trigger_info .info ul li {
        font-size: 10px;
        line-height: 12px;
        font-weight: 500;
        color: #121525;
        background: #e4e4e4;
        padding: 4px 10px;
        border-radius: 10px;
    }

    :deep(.journey_list) {
        position: relative;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
    }

    :deep(.journey_list>li),
    :deep(.journey_list>span>li) {
        border-radius: 8px;
        padding-bottom: 55px;
        position: relative;
        display: flex;
        justify-content: center;
        cursor: pointer;
        padding-top: 25px;
    }

    :deep(.journey_list>li:first-child),
    :deep(.journey_list>span>li:first-child) {
        padding-top: 25px;
    }

    :deep(.journey_list>li:before),
    :deep(.journey_list>span>li:before) {
        content: '';
        position: absolute;
        left: 50%;
        top: 0;
        bottom: 0;
        border-left: 1px solid #e9e9e9;
        z-index: -1;
    }

    .journey_list .sequence_item li:not(:last-child) {
        padding-bottom: 40px;
    }

    :deep(.journey_list li .step_info) {
        border: 1px solid #e9e9e9;
        background: #fff;
        position: relative;
        overflow: hidden;
    }

    :deep(.journey_list li .step_info.active) {
        border: 2px solid #bad7ff;
    }

    :deep(.journey_list li .step_info.draft>*:not(button)) {
        opacity: 0.5;
    }

    :deep(.journey_list li .remove_btn) {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        position: absolute;
        right: -25px;
        top: 6px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
    }

    :deep(.journey_list li .remove_btn i) {
        color: #5a5a5a !important;
    }

    :deep(.journey_list li .step_info:hover .remove_btn) {
        right: 8px;
    }

    :deep(.journey_list li .step_info.timer) {
        padding: 12px 20px;
        border-radius: 50px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        min-width: 230px;
        max-width: 260px;
        position: relative;
        z-index: 1;
    }

    :deep(.journey_list li .step_info.timer ul) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 4px;
        margin: 7px 0;
    }

    :deep(.journey_list li .step_info.timer ul li span) {
        font-size: 9px;
        line-height: 10px;
        font-weight: 500;
        color: #121525;
        background: #e4e4e4;
        padding: 2px 6px;
        border-radius: 10px;
        /* margin: 7px 0; */
        display: block;
    }

    :deep(.journey_list li .step_info.mail) {
        border-radius: 8px;
        flex: 0 0 260px;
        position: relative;
        z-index: 1;
    }

    :deep(.journey_list li .step_info.timer > i) {
        color: #999;
        margin-top: 2px;
        font-size: 12px;
    }

    :deep(.journey_list li .step_info.timer h4) {
        flex: 1;
        padding: 0 15px 0 10px;
        text-align: center;
        font-size: 11px;
        line-height: 16px;
        font-weight: 500;
        color: #5a5a5a;
    }

    :deep(.journey_list li .step_info.mail>i) {
        font-size: 14px;
        color: #999;
        padding: 5px 0;
        position: absolute;
        left: 15px;
        top: 7px;
    }

    :deep(.journey_list li .step_info.mail h4) {
        text-align: center;
        padding: 10px 20px;
        font-size: 13px;
        line-height: 18px;
        font-weight: 500;
        color: #5a5a5a;
        position: relative;
        border-bottom: 1px solid #e9e9e9;
    }

    :deep(.journey_list li .step_info.mail h4:hover) {
        cursor: move;
    }

    :deep(.journey_list li .step_info.mail h4 i) {
        position: absolute;
        left: 20px;
        top: 13px;
        opacity: 0;
        transition: all 0.3s ease-in-out;
    }

    :deep(.sequence_list li .step_info.mail:hover h4 i) {
        opacity: 1;
    }

    :deep(.journey_list li .step_info.mail .msg_cont .action_details) {
        padding: 20px 15px 30px 15px;
        display: flex;
        justify-content: center;
        text-align: center;
        position: relative;
    }

    :deep(.journey_list li .step_info.mail .msg_cont .action_details .info) {
        width: 20px;
        height: 20px;
        font-size: 10px;
        color: #5a5a5a;
        position: absolute;
        right: 10px;
        opacity: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #e9e9e9;
        border-radius: 50%;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
    }

    :deep(.journey_list li .step_info:hover .msg_cont .action_details .info) {
        opacity: 1;
    }

    :deep(.journey_list li .step_info .msg_cont .action_details .info .quick_info) {
        border-radius: 5px;
        border: 1px solid #eaeaea;
        box-shadow: 0 0 15px rgb(0 0 0 / 10%);
        background: #fff;
        max-width: 200px;
        min-width: 100px;
        text-align: center;
        position: absolute;
        right: -6px;
        top: auto;
        bottom: 100%;
        display: none;
        margin-bottom: 10px;
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 500;
        padding: 7px 10px;
        z-index: 1;
    }

    :deep(.journey_list li .step_info:hover .msg_cont .action_details .info .quick_info:after) {
        content: "";
        position: absolute;
        bottom: -6px;
        top: auto;
        right: 10px;
        width: 10px;
        height: 10px;
        border-bottom: 1px solid #e0e0e0;
        border-right: 1px solid #e0e0e0;
        border-top: 0;
        background: #fff;
        transform: rotate(45deg);
        z-index: 2;
    }

    :deep(.journey_list li .step_info .msg_cont .action_details .info:hover .quick_info) {
        display: block;
        animation: smoothMove 0.5s;
        animation-timing-function: cubic-bezier(1.0, 0.5);
    }

    @keyframes smoothMove {
        0% {
            bottom: 30%;
            opacity: 0;
        }

        10% {
            bottom: 30%;
            opacity: 0.5;
        }

        100% {
            bottom: 100%;
            opacity: 1;
        }
    }

    :deep(.journey_list li .step_info.mail .msg_cont .action_details h4) {
        border: 1px dashed #c9c9c9;
        padding: 8px 10px;
        font-size: 11px;
        line-height: 15px;
        font-weight: 500;
        color: #5a5a5a;
        min-width: 200px;
    }

    :deep(.journey_list li .step_info.mail .msg_cont .action_details p) {
        min-width: 200px;
        font-size: 9px;
        line-height: 12px;
        font-weight: 500;
        color: #2C3E50;
    }
    :deep(.journey_list li .step_info.mail .msg_cont .action_details p.more) {
        overflow: visible;
        display: flex;
        -webkit-line-clamp: none;
    }

    :deep(.journey_list li .step_info.mail .msg_cont .action_details ul) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 4px;
    }

    :deep(.journey_list li .step_info.mail .msg_cont .action_details ul li) {
        font-size: 10px;
        line-height: 12px;
        font-weight: 500;
        color: #121525;
        background: #e4e4e4;
        padding: 4px 10px;
        border-radius: 10px;
    }
    :deep(.journey_list li .step_info.mail .msg_cont .action_details * ){
        word-break: break-word;
    }

    :deep(.journey_list li .step_info.mail .msg_cont .mail_box),
    :deep(.journey_list li .step_info.mail .msg_cont .sms_box) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px 15px 5px 15px;
        max-width: 250px;
    }
    .journey_list li .step_info.mail .msg_cont .mail_box p.mail_subject{
        font-size: 11px;
        line-height: 14px;
    }

    :deep(.journey_list li .step_info.mail .msg_cont .mail_box .status_area),
    :deep(.journey_list li .step_info.mail .msg_cont .sms_box .status_area) {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    :deep(.journey_list li .step_info.mail .msg_cont .mail_box .score_circle) {
        width: 30px;
        height: 30px;
        padding: 2px;
    }

    :deep(.journey_list li .step_info.mail .msg_cont .mail_box .score_circle .inner_circle .score) {
        font-size: 15px;
        line-height: 18px;
    }

    :deep(.journey_list li .step_info.mail .msg_cont .msg_desc) {
        border: 1px dashed #c9c9c9;
        padding: 10px;
        text-align: center;
        min-width: 200px;
        background: #f5f5f5;
    }

    :deep(.journey_list li .step_info.mail .msg_cont .mail_body p) {
        font-size: 9px;
        line-height: 11px;
    }
    :deep(.journey_list li .step_info.mail .msg_cont .mail_body){
        margin-top: 6px;
        max-height: 44px;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        position: relative;
    }
    :deep(.journey_list li .step_info.mail .msg_cont .mail_body.more) {
        max-height: 100000vh;
        overflow: visible;
        display: flex;
        flex-direction: column;
        -webkit-line-clamp: none;
    }
    :deep(.journey_list>li.branch),
    :deep(.journey_list>span>li.branch) {
        padding-bottom: 0;
        /* margin-bottom: 50px; */
    }

    :deep(.branch_card) {
        border: 1px solid #e9e9e9;
        background: #fff;
        border-radius: 8px;
        max-width: 240px;
        margin: 10px auto 25px;
        position: relative;
        z-index: 2;
    }

    :deep(.branch_card .branch_info) {
        position: relative;
        overflow: hidden;
        padding: 30px 25px 20px 25px;
    }

    :deep(.branch_card:after) {
        content: '';
        position: absolute;
        top: 100%;
        height: 27px;
        left: 50%;
        border-left: 1px solid #e9e9e9;
    }

    :deep(.branch_card span) {
        width: 21px;
        height: 21px;
        background: #999;
        position: absolute;
        left: 50%;
        top: -11px;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: translateX(-50%) rotate(45deg);
    }

    :deep(.branch_card span i) {
        transform: rotate(-45deg);
        color: #fff;
    }

    :deep(.branch_card.active) {
        border: 2px solid #bad7ff;
    }

    :deep(.branch_card h4) {
        padding: 8px 10px;
        font-size: 11px;
        line-height: 15px;
        font-weight: 500;
        color: #5a5a5a;
        text-align: center;
        position: relative;
        border: 1px dashed #c9c9c9;
    }

    :deep(.branch_card h4 i) {
        position: absolute;
        font-size: 16px;
        top: 12px;
        left: 25px;
        color: #b9b9b9;
    }

    :deep(.branch_card:hover .remove_btn) {
        right: 10px;
    }

    :deep(.journey_list>li.branch .branch_wpr),
    :deep(.journey_list>span>li.branch .branch_wpr) {
        background: #fafafb;
        position: relative;
        z-index: 1;
    }

    :deep(.journey_list li.branch .branch_wpr>ul) {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 30px
    }

    :deep(.journey_list li.branch .branch_wpr>ul>li) {
        min-width: 260px;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
    }

    :deep(.journey_list>li.branch .branch_wpr>ul>li:first-child:before),
    :deep(.journey_list>span>li.branch .branch_wpr>ul>li:first-child:before) {
        content: '';
        position: absolute;
        left: 50%;
        right: -20px;
        top: 0;
        border-top: 1px solid #e9e9e9;
    }

    :deep(.journey_list>li.branch .branch_wpr>ul>li:last-child:before),
    :deep(.journey_list>span>li.branch .branch_wpr>ul>li:last-child:before) {
        content: '';
        position: absolute;
        right: 50%;
        left: -20px;
        top: 0;
        border-top: 1px solid #e9e9e9;
    }

    :deep(.journey_list>li.branch .branch_wpr>ul>li),
    :deep(.journey_list>span>li.branch .branch_wpr>ul>li) {
        position: relative;
        padding-top: 25px;
    }

    :deep(.journey_list>li.branch .branch_wpr>ul>li .journey_list),
    :deep(.journey_list>span>li.branch .branch_wpr>ul>li .journey_list) {
        padding-bottom: 55px;
    }

    :deep(.journey_list>li.branch .branch_wpr>ul>li:after),
    :deep(.journey_list>span>li.branch .branch_wpr>ul>li:after) {
        content: '';
        position: absolute;
        left: 50%;
        top: 0;
        bottom: 0;
        transform: translateX(-50%);
        border-left: 1px solid #e9e9e9;
        z-index: 0;
    }

    :deep(.journey_list>li.branch .branch_wpr>ul>li .icons),
    :deep(.journey_list>span>li.branch .branch_wpr>ul>li .icons) {
        width: 42px;
        height: 42px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #e9e9e9;
        background: #fff;
        position: relative;
        z-index: 2;
    }

    :deep(.journey_list>li.branch .branch_wpr>ul>li .icons i),
    :deep(.journey_list>span>li.branch .branch_wpr>ul>li .icons i) {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        color: #fff;
    }

    :deep(.journey_wpr .step_type) {
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        z-index: 2;
    }

    /* .journey_wpr .step_type.trigger_add{
            position: relative;
        } */
    :deep(.journey_wpr .add_new) {
        width: 30px;
        height: 30px;
        font-size: 12px;
        border: 1px solid #e9e9e9;
        background: #fff;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #5a5a5a;
        cursor: pointer;
    }

    :deep(.add_types) {
        width: 370px;
        position: absolute;
        top: 50px;
        left: 50%;
        transform: translateX(-50%);
        background: #fff;
        border: 1px solid #e9e9e9;
        border-radius: 8px;
        box-shadow: 5px 50px 50px rgba(0, 0, 0, 0.1);
        text-align: center;
        display: none;
    }

    :deep(.step_type.active .add_types) {
        display: block;
    }

    :deep(.add_types:after) {
        position: absolute;
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 262.83 148.44'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;stroke:%23c7c6c6;stroke-miterlimit:10;stroke-width:7px;%7D%3C/style%3E%3C/defs%3E%3Cpolyline class='cls-1' points='2.58 2.36 131.41 143.25 260.24 2.36'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        width: 20px;
        height: 13px;
        left: calc(50% - 10px);
        bottom: 100%;
        transform: scale(-1);
    }

    :deep(.journey_wpr .step_type.top .add_types) {
        bottom: 50px;
        top: auto;
        box-shadow: 5px -20px 50px rgba(0, 0, 0, 0.1);
    }

    :deep(.journey_wpr .step_type.top .add_types:after) {
        top: 100%;
        bottom: auto;
        transform: scale(1);
    }

    :deep(.add_types h3) {
        font-size: 13px;
        line-height: 17px;
        color: #121525;
        font-weight: 500;
        padding: 12px 15px;
    }

    :deep(.add_types ul) {
        display: flex;
        justify-content: center;
        padding: 15px;
        border-top: 1px solid #e9e9e9;
    }

    :deep(.add_types ul li) {
        width: 33.333%;
        padding: 7px;
    }
    :deep(.add_types ul li .card_item){
        padding: 10px;
        font-size: 11px;
        line-height: 15px;
        border-radius: 5px;
    }
    :deep(.add_types ul li .card_item i){
        font-size: 16px;
        color: #999;
    }

    :deep(.add_types ul li i) {
        font-size: 25px;
        color: #c4c4c4;
        margin-bottom: 10px;
    }

    :deep(.add_types ul li h4) {
        font-weight: 500;
    }

    :deep(.tabs_content .step_details .extra_info) {
        padding: 20px;
        font-size: 12px;
        line-height: 19px;
        font-weight: 500;
        color: #121525;
        display: block;
        margin-bottom: 15px;
        background: #e4e4e4;
        border: 1px solid #d9d9d9;
    }

    .tabs_content .acton_header {
        border-bottom: 1px solid #e9e9e9;
        margin: 0 -40px;
        padding: 0 45px 15px 45px;
    }

    .preview_btn {
        padding: 10px 20px;
        border-radius: 3px;
        background: #32373b;
        color: #fff;
        font-size: 12px;
        line-height: 15px;
        font-weight: 300;
        position: fixed;
        right: -30px;
        top: 100px;
        z-index: 2;
        text-transform: uppercase;
        letter-spacing: 1px;
        transform: rotate(-90deg);
        cursor: pointer;
    }

    .selected_actn_card {
        padding: 20px 30px;
        border: 1px solid #2f7eed;
        border-radius: 12px;
        margin: 15px 0;
    }

    .selected_actn_card h3 {
        font-size: 20px;
        line-height: 25px;
        font-weight: 500;
        color: #2f7eed;
        margin: 0 0 5px;
    }

    .selected_actn_card h6 {
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        color: #5a5a5a;
    }

    .edit_section {
        margin-top: 25px;
    }

    .new_trigger {
        position: absolute;
        left: 100%;
        top: 40px;
    }

    .new_trigger.left {
        position: absolute;
        right: 100%;
        left: auto;
        top: 40px;
    }

    .notes {
        max-width: 340px;
        text-align: center;
        font-family: "Shadows", sans-serif;
        font-size: 20px;
        line-height: 30px;
        color: #adadad;
        margin: 30px 0;
    }

    .journey_details .add_btn {
        margin: 5px 0 5px auto;
        font-size: 13px;
        line-height: 16px;
    }

    .journey_details .add_btn i {
        font-size: 11px;
    }

    .condition_wpr .divider {
        padding: 10px 0;
        position: relative;
    }

    .condition_wpr .divider:before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        right: 0;
        border-top: 1px solid #e9e9e9;
    }

    .condition_wpr .divider span {
        background: #fff;
        position: relative;
        z-index: 1;
        padding-right: 10px;
        font-size: 14px;
        line-height: 17px;
        text-transform: uppercase;
        color: #5a5a5a;
        font-weight: 500;
        letter-spacing: 1px;
    }

    :deep(.condition_wpr .remove_btn),
    :deep(.trigger_info .remove_btn) {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        position: absolute;
        right: 10px;
        opacity: 0;
        top: 7px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
    }

    .condition_wpr .edit_section {
        position: relative;
    }

    :deep(.condition_wpr .edit_section:hover .remove_btn),
    :deep(.trigger_info:hover .remove_btn) {
        opacity: 1;
    }

    .condition_prompt .action_wpr .btn {
        margin: 0 20px;
        min-width: 80px;
        border: 0;
    }

    .condition_prompt .action_wpr .btn.leg2 {
        background: #f2a31d;
    }

    .no-before:before {
        border-top: 0px solid #e9e9e9 !important;
    }

    .profile_spinner_wpr {
        z-index: +9;
    }

    .preview_btn {
        padding: 10px 20px;
        border-radius: 3px;
        background: #32373b;
        color: #fff;
        font-size: 12px;
        line-height: 15px;
        font-weight: 300;
        position: fixed;
        right: -30px;
        top: 100px;
        text-transform: uppercase;
        letter-spacing: 1px;
        transform: rotate(-90deg);
        cursor: pointer;
    }

    :deep(span.action-chip) {
        font-size: 10px;
        line-height: 12px;
        font-weight: 500;
        color: #121525;
        background: #e4e4e4;
        padding: 4px 10px;
        border-radius: 10px;
    }

    :deep(.attch_icon) {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #e4e4e4;
        font-size: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 3px auto 0 auto;
    }

    :deep(.cloud-chip) {
        font-size: 10px;
        line-height: 12px;
        font-weight: 500;
        color: #121525;
        background: #e4e4e4;
        padding: 4px 10px;
        border-radius: 10px;
        margin: 2px;
        white-space:nowrap;
    }

    :deep(.task-users) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .timezone-warning {
        font-size: 12px;
        line-height: 15px;
        color: #f2a31e;
        font-weight: 500;
    }

    .color-d4 {
        color: #D4D4D4;
    }

    :deep(.add_types ul:before) {
        border: 0px solid #e9e9e9;
    }

    .blink_me {
        animation: blinking 1s linear infinite;
    }

    @keyframes blinking {
        0% {
            background-color: #ffffff;
        }
        100% {
            background-color: #fafafb;
        }
    }

    :deep(.invalid-action .step_info),
    :deep(.invalid-action .branch_card) {
        border-color: #eb1414 !important;
    }


    .share_playbook .modal_container {
        height: 640px;
    }

    .share_playbook .modal_container .modal_header {
        padding: 20px 30px 0;
    }

    .share_playbook .modal_container .modal_body {
        padding: 0 30px;
        flex-direction: column;
    }

    .share_playbook .tab_wpr {
        width: 100%;
    }

    .share_playbook .result_wpr  {
        width: 100%;
    }

    .result_wpr table.standard td:first-child, .result_wpr table.standard th:first-child {
        width: auto;
        text-align: left;
        padding-left: 10px;
    }

    .result_wpr table.standard tbody.no_record td:first-child {
        width: auto;
        text-align: center;
    }

    .modal_footer {
        border-radius: 0 0 12px 12px;
    }

    .action_list li i.fa-trash-alt{
        color: #eb1414;
    }

    .plan_type {
        display: flex;
        font-size: 14px;
        line-height: 17px;
        align-items: center;
        gap: 10px;
        font-weight: 500;
        color: #121525;
    }

    .w-120 {
        width: 120px;
    }

    .right_section.w-120 {
        text-align: right;
    }
    .mobile_alert{
        position: fixed;
        left: 0;
        height: 100vh;
        right: 0;
        background: #fff;
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 4;
        padding: 15px;
    }
    .mobile_alert h3{
        font-size: 22px;
        line-height: 32px;
        font-weight: 300;
        color: #999;
    }
    .mobile_alert .back_btn{
        font-size: 14px;
        line-height: 20px;
        color: #5a5a5a;
        font-weight: 500;
        margin-top: 30px;
        display: flex;
        align-items: center;
        padding: 8px 20px;
        background: #f5f5f5;
        border-radius: 20px;
        cursor: pointer;
    }
    .mobile_alert .back_btn i{
        margin-right: 10px;
    }
    .loader_area{
        left: 0;
        position: absolute;
        height: 100vh;
    }

    .extra_note{
        padding: 25px 20px;
        background: #fbfbfb;
        border-radius: 5px;
        border: 1px solid #f5f5f5;
        font-size: 13px;
        line-height: 20px;
        font-weight: 500;
        color: #5a5a5a;
        text-align: center;
    }
    .starting_plan :deep(.vc-container){
        background: transparent
    }
    .starting_plan :deep(.vc-container .vc-day) {
        margin: 0;
        min-height: 40px;
    }
    .start_plan :deep(.vc-container .vc-day .vc-highlight) {
        width: 28px;
        height: 28px;
    }
    .start_plan :deep(.vc-container .vc-day-content) {
        width: 28px;
        height: 28px;
        font-size: 11px;
        background: #deecff;
        color: #121525 !important;
    }
    .start_plan :deep(.vc-container .vc-day-content:focus) {
        background: #2f7eed;
        color: #fff !important;
    }
    .start_plan :deep(.vc-container .vc-day-content.is-disabled) {
        background: #f1f1f1;
        color: #aaa !important;
    }

    @media(max-width: 767px){
        .global_setting .tabs_content{
            position: absolute;
            width: 340px;
            right: -340px;
            left: auto;
            border-left: 1px solid #eee;
        }
        .global_setting .tabs_content.show{
            right: 0;
        }
        .mobile_alert{
            display: flex;
        }
    }
    @media(max-width: 499px){
        .mobile_alert h3{
            font-size: 18px;
            line-height: 25px;
        }
        .mobile_alert .back_btn{
            font-size: 13px;
            line-height: 18px;
            margin-top: 20px;
        }
    }
</style>
